import React, {useEffect, useState} from "react";

import './chat-canvas.scss'
import PropTypes from "prop-types";
import {useRecoilState, useRecoilValue} from "recoil";
import {wpClientSelector} from "../../../state/api";
import TimeSince from "../../../utils";
import {GetCommentAvatar} from "../../../utils/Comment";
import InfiniteScroll from "react-infinite-scroll-component";
import {Link} from "react-router-dom";
import Load from "../../Load";
import {userStateSelector} from "../../../state/auth";
import UserState from "../../../models/UserState";

const ChatCanvas = ({user, recipient}) => {
  const pwd = process.env.REACT_APP_WP_SEC_CHAT_PWD
  const messagesPageSize = 25;
  const wpClient = useRecoilValue(wpClientSelector)
  const [userState, setUserState] = useRecoilState(userStateSelector)
  const [chat, setChat] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [messages, setMessages] = useState([])
  const [sending, setSending] = useState(false)
  const [scrollRef, setScrollRef] = useState(null)
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0);
  const [reloadLastPageCounter, setReloadLastPageCounter] = useState(0)

  useEffect(() => {
    if (!recipient) {
      return
    }
    resetChatState()
    setLoading(true)
    loadChat()
  }, [user, recipient]);

  useEffect(() => {
    if (reloadLastPageCounter <= 0) {
      return
    }
    reloadLastPage()
  }, [reloadLastPageCounter]);

  const reloadLastPage = () => {
    wpClient
      .comments()
      .post(chat.id)
      .perPage(messagesPageSize)
      .page(1)
      .order('desc').orderby('date')
      .get()
      .then(function (response) {
        if (response.length > 0) {
          const newMsgs = response.filter(it => messages.find(msg => msg.id === it.id) === undefined)
          if (newMsgs.length > 0) {
            updateMessagesList(newMsgs)
            updateUserChatState(chat.id)
          }
        }
      })
      .catch(error => {
        console.error(error)
      });
  }

  const resetChatState = () => {
    setMessages([])
    setChat(null)
    setSending(false)
    setPage(1)
    setTotalPages(0)
    setError(false)
    setLoading(true)
  }

  useEffect(() => {
    if (chat == null) {
      return
    }
    fetchNextPage(page)
    const intervalId = setInterval(
      () => setReloadLastPageCounter(counter => counter + 1),
      5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [chat]);

  const keepScrollPosition = () => {
    if (!scrollRef) {
      return
    }
    setTimeout(() => {
      scrollRef.scrollTop = scrollPosition
    }, (1));
  }

  const fetchNextPage = (nextPage) => {
    wpClient
      .comments()
      .post(chat.id)
      .perPage(messagesPageSize)
      .page(nextPage)
      .order('desc').orderby('date')
      .get()
      .then(function (response) {
        if (response.length > 0) {
          updateMessagesList(response)
          keepScrollPosition()
          setTotalPages(response['_paging'].totalPages)
          setPage(nextPage)
        }
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }

  useEffect(() => {
    if (!scrollRef) {
      return
    }
    scrollToBottom()
  }, [scrollRef, messages]);

  const updateMessagesList = (list) => {
    if (messages.length === 0) {
      setMessages(list)
    } else {
      if (list.length === 1) {
        const newMessages = [
          ...list,
          ...messages.slice(0)
        ];
        setMessages(newMessages)
      } else {
        messages.push(...list)
      }
    }
  }

  const scrollToBottom = () => {
    scrollRef.scrollTop = 0;
  }

  const loadChat = () => {
    wpClient.chats()
      .password(pwd)
      .perPage(1)
      .param('meta_query[0][key]', 'users')
      .param('meta_query[0][value]', `_${user.id}__${recipient.id}_`)
      .param('meta_query[0][compare]', '=')
      .param('meta_query[1][key]', 'users')
      .param('meta_query[1][value]', `_${recipient.id}__${user.id}_`)
      .param('meta_query[1][compare]', '=')
      .param('meta_query[relation]', 'OR')
      .embed()
      .then(function (response) {
        if (response.length !== 1) {
          setLoading(false)
          return
        }
        setChat(response[0])
        updateUserChatState(response[0].id)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      })
  }

  const updateUserChatState = (chatId) => {
    let newUserState = {
      ...userState,
      chats: {
        ...userState.chats,
        [chatId]: {
          ...userState.chats[chatId],
          timestamp: Math.floor(Date.now() / 1000)
        }
      }
    }
    const {...plainStateObj} = newUserState
    const req = {
      ...user,
      state: JSON.stringify(plainStateObj)
    }
    return wpClient.users().me().update(req)
      .then(function (response) {
        setUserState(new UserState(response.state))
      })
      .catch(error => {
        console.error(error)
      })
  }

  if (!recipient) {
    return (
      <div className="chat-body no-chat-selected p-3 scroll-bar">
        <div className="messages-content pb-5">
          <h1>Nessuna chat selezionata</h1>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="chat-body no-chat-selected p-3 scroll-bar">
        <div className="messages-content pb-5">
          <div className="dot-typing"></div>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="chat-body no-chat-selected p-3 scroll-bar">
        <div className="messages-content pb-5">
          <h1>Si è verificato un errore. Prova a ricaricare la pagina</h1>
        </div>
      </div>
    )
  }

  const MessageItem = ({comment}) => {
    const outgoing = comment.author === user.id
    return (
      <div className={`message-item ${outgoing && "outgoing-message"}`}>
        <div className="message-user">
          <figure className="avatar">
            <img src={GetCommentAvatar(comment)} alt="avater"/>
          </figure>
          <div>
            <h5>
              <Link style={{color: "#111"}} to={outgoing ? `/impostazioni/account` : `/utenti/${comment.author}`}>
                {comment.author_name}
              </Link>
            </h5>
            <div className="time">{TimeSince(new Date(comment.date))}</div>
          </div>
        </div>
        <div className="message-wrap" dangerouslySetInnerHTML={{__html: comment.content.rendered}}/>
      </div>
    )
  }

  const onSubmit = (ev) => {
    if (sending) {
      return
    }
    ev.stopPropagation()
    ev.preventDefault()
    const messageInput = document.getElementById("message")
    setSending(true)
    fetchOrCreateChat()
      .then(function (chat) {
        setChat(chat)
        sendMessage(chat.id, messageInput.value)
          .then(response => {
            messageInput.value = ''
            updateMessagesList([response])
            setSending(false)
            updateChatTimestamp(chat)
          })
          .catch(error => {
            console.error(error)
            setSending(false)
          })
      })
  }

  const updateChatTimestamp = (chat) => {
    const timestamp = Math.floor(Date.now() / 1000)
    wpClient.chats().id(chat.id)
      .embed()
      .update({
        acf: {
          users: `${chat.acf.users}`,
          timestamp: timestamp
        }
      })
      .then(response => {
        updateUserChatState(response.id)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const fetchOrCreateChat = () => {
    if (chat != null) {
      return Promise.resolve(chat)
    }
    const chatId = crypto.randomUUID()
    return wpClient.chats()
      .create({
        title: chatId,
        author: user.id,
        status: 'publish',
        comment_status: 'open',
        password: pwd,
        acf: {
          users: `_${user.id}__${recipient.id}_`
        }
      })
  }

  const sendMessage = (chatId, commentContent) => {
    return wpClient.comments()
      .create({
        author: user.id,
        author_email: user.user_email,
        author_name: user.name,
        content: commentContent,
        post: chatId,
        parent: 0
      });
  }

  const handleScroll = (event) => {
    setScrollPosition(event.target.scrollTop)
  }

  return (
    <div id="chat-body"
         className={`chat-body m-3 scroll-bar position-relative ${messages.length === 0 && "no-chat-selected"}`}
         ref={(el) => {
           setScrollRef(el)
         }}
         style={{
           overflow: 'auto',
           display: 'flex',
           flexDirection: 'column-reverse',
         }}>
      <div>
        <div id="messages-content"
             className="messages-content pb-5 scroll-bar">
          {
            messages.length === 0 &&
            <h2>Non sono presenti messaggi. Scrivi tu il primo!</h2>
          }
          <InfiniteScroll
            next={() => fetchNextPage(page + 1)}
            hasMore={page < totalPages}
            loader={<Load/>}
            dataLength={messages.length}
            inverse={true}
            scrollableTarget="chat-body"
            style={{display: 'flex', flexDirection: 'column-reverse'}}
            onScroll={handleScroll}
            endMessage={
              (messages.length > 0 && messages.length > messagesPageSize) &&
              <p style={{textAlign: "center"}}>
                Non ci sono altri messaggi in questa conversazione.
              </p>
            }>
            {
              messages.map((message, index) => <MessageItem key={index} comment={message}/>)
            }
          </InfiniteScroll>
        </div>
      </div>
      <div className="chat-bottom p-3 shadow-none" onClick={() => document.getElementById("message").focus()}>
        <form className="chat-form" onSubmit={() => {}}>
          <div className="form-group">
            <textarea id="message" placeholder="Messaggio.."/>
          </div>
          <button onClick={onSubmit} disabled={sending} className="bg-current"><i className="ti-arrow-right text-white"></i></button>
        </form>
      </div>
    </div>
  )
}

ChatCanvas.propTypes = {
  user: PropTypes.object.isRequired,
  recipient: PropTypes.object,
}

export default ChatCanvas