
const GetUserAvatar = (user) => {
  if (user == null || user["avatar_urls"].length === 0) {
    return "/assets/images/user.png"
  }
  return user["avatar_urls"][96]
}

const GetUserAvatarFull = (user) => {
  if (user == null) {
    return "/assets/images/user.png"
  }
  if (!user["simple_local_avatar"] || !user["simple_local_avatar"]["full"] || user["simple_local_avatar"].length === 0) {
    return GetUserAvatar(user)
  }
  return user["simple_local_avatar"]["full"]
}

export {
  GetUserAvatar,
  GetUserAvatarFull
}