import React, {useEffect, useState} from "react";

import './PostComments.scss'
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import PropTypes from "prop-types";
import Load from "../Load";
import {GetUserAvatar} from "../../utils/User";
import TimeSince from "../../utils";
import {GetCommentAvatar} from "../../utils/Comment";
import AvatarImg from "../AvatarImg";
import {Link} from "react-router-dom";

const PostComments = (props) => {
  const wpClient = useRecoilValue(wpClientSelector)
  const postId = props.postId
  const user = props.user
  const [comments, setComments] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [saving, setSaving] = useState(false)

  const [parentComment, setParentComment] = useState(null)
  const [threadId, setThreadId] = useState(-1)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    wpClient
      .comments()
      .post(postId)
      .perPage(100)
      .order('asc').orderby('date')
      .get()
      .then(function (response) {
        setComments(response)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [postId])

  if (error) {
    return (
      <span>Errore!</span>
    )
  }

  if (loading) {
    return (
      <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
        <div className="card-body p-0 d-flex">
          <Load/>
        </div>
      </div>
    )
  }

  const onCommitSubmit = () => {
    setSaving(true)
    const commentContent = document.getElementById('comment-text-input').value;
    wpClient.comments()
      .create({
        author: user.id,
        author_email: user.user_email,
        author_name: user.name,
        content: commentContent,
        post: postId,
        parent: threadId > 0 ? threadId : 0,
      })
      .then(function () {
        window.location.reload()
      })
      .catch(error => {
        console.error(error)
        setSaving(false)
      });
  }

  const onCommitUpdate = () => {
    setSaving(true)
    const commentContent = document.getElementById('comment-text-input').value;
    wpClient.comments().id(parentComment.id)
      .update({
        content: commentContent,
      })
      .then(function () {
        window.location.reload()
      })
      .catch(error => {
        console.error(error)
        setSaving(false)
      });
  }

  const deleteComment = (id) => {
    if (window.confirm("Sei sicuro di voler eliminare il commento?")) {
      wpClient.comments().id(id)
        .delete()
        .then(function () {
          window.location.reload()
        })
        .catch(error => {
          console.error(error)
          setSaving(false)
        });
    }
  }

  const replyToComment = (parent, threadId) => {
    setParentComment(parent)
    setThreadId(threadId)
    document.getElementById('comment-text-input').focus()
  }

  const cancelReplyComment = () => {
    setParentComment(null)
    setThreadId(-1)
  }

  const editToComment = (comment) => {
    setParentComment(comment)
    setEditMode(true)
    document.getElementById('comment-text-input').value = comment.content.rendered.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, '');
    document.getElementById('comment-text-input').focus()
  }

  const cancelEditComment = () => {
    setParentComment(null)
    setEditMode(false)
  }

  const Comment = (props) => {
    const comment = props.comment
    const threadId = props.threadId
    return (
      <div key={comment.id} className="post-comment-card card w-100 shadow-xss rounded-xxl border-0">
        <div className="card-body p-0 mt-3 position-relative">
          <figure className="avatar position-absolute ms-2 mt-1 top-5">
            <Link style={{color: "#111"}} to={`/utenti/${comment.author}`}>
              <AvatarImg src={GetCommentAvatar(comment)} classes="comment-avatar shadow-sm rounded-circle w30"/>
            </Link>
          </figure>
          <div className="w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 theme-dark-bg">
            <h4>
              <Link style={{color: "#111"}} to={`/utenti/${comment.author}`}>
                {comment.author_name ? comment.author_name : "senza nome"}
              </Link>
            </h4>
            <div className="comment-content" dangerouslySetInnerHTML={{__html: comment.content.rendered}}/>
            <span title={`Rispondi a ${comment.author_name}`} onClick={() => replyToComment(comment, threadId)}
                  className="reply-comment-link">Rispondi</span>
            {
              user.id === comment.author &&
              <>
                <span title="Modifica il tuo commento" onClick={() => editToComment(comment)}
                      className="edit-comment-link">Modifica</span>
                <span title="Elimina il tuo commento" onClick={() => deleteComment(comment.id)}
                      className="delete-comment-link">Elimina</span>
              </>
            }
            <span>{TimeSince(new Date(comment.date))}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="post-comments card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
      <div className="subtitle-data card-body p-0 d-flex mb-3">
        <h1>Commenti {comments.length > 0 ? `(${comments.length})` : ""}</h1>
        <div className="author-info">
        </div>
      </div>
      {
        comments.length === 0 && <span>Non sono presenti commenti. Aggiungi tu il primo!</span>
      }
      {
        comments.filter(comment => comment.parent === 0).map(comment =>
          <div key={comment.id}>
            <Comment threadId={comment.id} comment={comment}/>
            {
              comments
                .filter(childComment => childComment.parent === comment.id)
                .map(childComment =>
                  <div key={childComment.id} className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">
                      <Comment threadId={comment.id} comment={childComment}/>
                    </div>
                  </div>
                )
            }
          </div>
        )
      }
      <br/>
      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
        <div className="card-body p-0">
          <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>
          {
            parentComment == null ? "Aggiungi commento" :
              <span onClick={editMode ? cancelEditComment : cancelReplyComment} className="reply-title">
                {
                  editMode
                    ? "Modificando commento (clicca per annullare)"
                    : "In risposta a " + parentComment.author_name + " (clicca per annullare)"
                }
              </span>
          }
        </div>
        <div className="card-body p-0 mt-3 position-relative">
          <figure className="avatar position-absolute ms-2 mt-1 top-5">
            <AvatarImg src={GetUserAvatar(user)} classes="comment-avatar shadow-sm rounded-circle w30"/>
          </figure>
          <textarea
            id="comment-text-input"
            name="message"
            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xsss fw-500 border-light-md theme-dark-bg"
            cols="30"
            rows="10"
            placeholder="Scrivi qui il tuo commento.."/>
        </div>
        <div className="col-lg-12">
          <button
            type="button"
            onClick={editMode ? onCommitUpdate : onCommitSubmit}
            disabled={saving}
            className={"send-comment-btn text-center text-white font-xsss p-3 rounded-3 d-inline-block " + (saving ? "disabled" : "")}>
            {saving ? "Attendi.." : editMode ? "Modifica" : "Invia"}
          </button>
        </div>
      </div>
    </div>
  )
}

PostComments.propTypes = {
  postId: PropTypes.number.isRequired,
  user: PropTypes.object,
}

export default PostComments