import React, {useState, useRef} from 'react'

import 'react-image-crop/dist/ReactCrop.css'
import './ImageCropper.scss'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop'
import {canvasPreview} from './CanvasPreview'
import {useDebounceEffect} from './UseDebounceEffect'
import PropTypes from "prop-types";

function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const ImageCropper = (props) => {
  const onCropTentative = props.onCropTentative
  const [imgSrc, setImgSrc] = useState('')
  const [imageFn, setImageFn] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 2097152) {
        window.alert("L'immagine è troppo grande. Devi caricare un'immagine di dimensioni non superiori a 2MB")
        window.location.reload()
        return
      }
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
        setImageFn(e.target.files[0].name)
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e) {
    const {width, height} = e.currentTarget
    setCrop(centerAspectCrop(width, height, 1))
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          "avatar"
        ).then((blob) => {
          onCropTentative(new File([blob], imageFn, {type: blob.type}))
        })
      }
    },
    100,
    [completedCrop],
  )

  return (
    <div className="App">
      <div className="Crop-Controls">
        <input type="button" id="loadAvatarFile" value="Modifica immagine profilo"
               style={{padding: "10px"}}
               onClick={() => document.getElementById('avatar-file').click()} />
        <input id="avatar-file" type="file" accept="image/*" onChange={onSelectFile} style={{display: "none"}}/>
      </div>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={1}
          minWidth={200}
          minHeight={200}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{transform: `scale(1)`, maxHeight: "400px"}}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {!!completedCrop && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

ImageCropper.propTypes = {
  onCropTentative: PropTypes.func.isRequired,
}

export default ImageCropper
