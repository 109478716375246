import React, {useEffect, useState} from "react";

import './SectionsList.scss'
import {Link} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import Load from "../Load";

const SectionsList = () => {
  const wpClient = useRecoilValue(wpClientSelector)
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const sectionsParentCat = process.env.REACT_APP_SECTIONS_PARENT_CATEGORY_ID

  useEffect(() => {
    wpClient.categories().parent(sectionsParentCat)
      .then(function (categories) {
        setCategories(categories)
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setError(true)
        setLoading(false)
      })
  }, [])

  if (error) {
    return (
      <span>Errore!</span>
    )
  }

  if (loading) {
    return (
      <Load/>
    )
  }

  return (
    <div style={{marginTop: "14px"}} className="nav-wrap bg-white bg-transparent-card rounded-xxl pt-3 pb-1 mb-2 ">
      <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Sezioni</span></div>
      <ul className="mb-1 top-content">
        {
          categories.filter(cat => !cat.acf["post_creation_enabled"]).map(cat => {
            return (
              <li key={cat.id}>
                <Link to={"/sezioni/" + cat.slug} className="nav-content-bttn open-font nav-hover">
                  <i className={cat.acf["category_feather_icon"] + ` btn-round-md bg-${cat.acf.color}-gradiant me-3`}/>
                  {cat.name.replace(/&amp;/g, '&')}
                  {
                    cat.acf["post_creation_enabled"] &&
                    <i title="Sezione aperta" className="feather-edit-2 font-xsss text-grey-500 ms-auto me-3"></i>
                  }
                </Link>
              </li>
            )
          })
        }
      </ul>
      <div className="nav-caption fw-600 font-xssss text-grey-500 mt-4"><span>Sezioni insieme</span></div>
      <ul className="mb-1 top-content">
        {
          categories.filter(cat => cat.acf["post_creation_enabled"]).map(cat => {
            return (
              <li key={cat.id}>
                <Link to={"/sezioni/" + cat.slug} className="nav-content-bttn open-font nav-hover">
                  <i className={cat.acf["category_feather_icon"] + ` btn-round-md bg-${cat.acf.color}-gradiant me-3`}/>
                  {cat.name.replace(/&amp;/g, '&')}
                  {
                    cat.acf["post_creation_enabled"] &&
                    <i title="Sezione aperta" className="feather-edit-2 font-xsss text-grey-500 ms-auto me-3"></i>
                  }
                </Link>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default SectionsList