import React from "react";

import './SectionProfileDetail.scss'
import PropTypes from "prop-types";

const SectionProfileDetail = (props) => {
  const category = props.category
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">Informazioni</h4>
        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{category.description}</p>
      </div>
      <div className="card-body border-top-xs d-flex">
        <i className="feather-eye text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">Pubblico <span
          className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Sezione accessibile a chiunque</span></h4>
      </div>
    </div>
  )
}

SectionProfileDetail.propTypes = {
  category: PropTypes.object.isRequired,
}

export default SectionProfileDetail