import React, {useEffect, useState} from "react";

import './SearchResult.scss'
import {useNavigate, useParams} from "react-router-dom";
import Load from "../../components/Load";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import PostView from "../../components/PostView";
import PropTypes from "prop-types";
import WpPost from "../../models/WpPost";

const SearchResult = (props) => {
  const {keywords} = useParams();
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const wpClient = useRecoilValue(wpClientSelector)
  const navigate = useNavigate();
  const [posts, setPosts] = useState([])
  const user = props.user
  const pwd = process.env.REACT_APP_WP_SEC_PWD

  useEffect(() => {
    if (keywords.length === 0) {
      setError("I criteri di ricerca non sono validi")
      setLoading(false)
      return
    }
    setLoading(true)
    setError("")
    wpClient.posts().search(keywords).password(pwd).embed()
      .then(function (response) {
        if (response.length === 0) {
          setError(`La ricerca per '${keywords}' non ha prodotto alcun risultato`)
        }
        setPosts(response.map(post => new WpPost(post)))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError("Si è verificato un errore durante la ricerca. Riprovare più tardi")
        setLoading(false)
      });
  }, [keywords])

  if (loading) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <p className="back-button" onClick={() => navigate(-1)}><i className="feather-arrow-left"/> Indietro</p>
                <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="card-body p-0 d-flex">
                    <Load/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (error.length !== 0) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <p className="back-button" onClick={() => navigate(-1)}><i className="feather-arrow-left"/> Indietro</p>
                <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="card-body p-0 d-flex">
                    <span>{error}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="row">
            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <p className="back-button" onClick={() => navigate(-1)}><i className="feather-arrow-left"/> Indietro</p>
              <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                <h3>Risultati della ricerca: '{keywords}'</h3><br/>
                {
                  posts.map(post =>
                    <div key={post.id} className="card-body p-0 d-flex">
                      <PostView key={post.id} post={post} user={user} />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SearchResult.propTypes = {
  user: PropTypes.object,
}

export default SearchResult