
class UserState {
  constructor(object) {
    if (object === "") {
      this.posts = {}
      this.chats = {}
      return
    }
    try {
      const state = JSON.parse(object)
      this.posts = state.posts !== undefined ? state.posts : {}
      this.chats = state.chats !== undefined ? state.chats : {}
    } catch (error) {
      this.posts = {}
      this.chats = {}
    }
  }
}

export default UserState