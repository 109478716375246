import React from "react";

import './AppFooter.scss'

import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import AvatarImg from "../AvatarImg";
import {GetUserAvatar} from "../../utils/User";

const AppFooter = ({user, unreadNotifications, unreadChats}) => {
  return (
    <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
      <Link to="/home" className="nav-content-bttn nav-center"><i className="feather-home"></i></Link>
      <Link to="/eventi" className="nav-content-bttn"><i className="feather-calendar"></i></Link>
      <Link to="/chat" className="nav-content-bttn">
        <i className="feather-message-square position-relative">
          {
            unreadChats.length > 0 &&
            <div className="notification-tag" />
          }
        </i>
      </Link>
      <Link to="/notifiche" className="nav-content-bttn" data-tab="chats">
        <i className="feather-bell position-relative">
          {
            unreadNotifications > 0 &&
            <div className="notification-tag" />
          }
        </i>
      </Link>
      <Link to="/impostazioni" className="nav-content-bttn">
        <figure title="Visualizza profilo" className="avatar" style={{marginBottom: "0"}}>
          <AvatarImg src={GetUserAvatar(user)} classes="shadow-sm rounded-circle w35 h35"/>
        </figure>
      </Link>
    </div>
  )
}

AppFooter.propTypes = {
  user: PropTypes.object,
  unreadNotifications: PropTypes.number.isRequired,
  unreadChats: PropTypes.array.isRequired,
}

export default AppFooter;