import React, {useEffect, useState} from "react";

import './Events.scss'
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import WpEvent from "../../models/WpEvent";
import Load from "../../components/Load";
import {Link} from "react-router-dom";
import {decodeHtml} from "../../utils/Escape";

const Events = () => {
  const wpClient = useRecoilValue(wpClientSelector)
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const pwd = process.env.REACT_APP_WP_SEC_PWD

  useEffect(() => {
    wpClient.events()
      .perPage(100)
      .password(pwd)
      .embed()
      .then(function (response) {
        setEvents(response.map(event => new WpEvent(event)))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [])

  if (error) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <h4>Si è verificato un errore durante il caricamento degli eventi</h4>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <Load/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (events.length === 0) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <h4>Non ci sono eventi al momento</h4>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row events-page-container">
            {events
              .sort((a, b) => (a.date < b.date) ? 1 : -1)
              .map((value, index) => (
                <Link key={index} to={`/eventi/${value.slug}`} className="col-lg-4 col-md-6 pe-2 ps-2">
                  <div
                    className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                    <div className="card-image">
                      <img src={value.imageUrl} alt="event" className="rounded-3"/>
                    </div>
                    <div className="card-body d-flex ps-0 pe-0 pb-0">
                      <div className={`card-date me-3 p-3 border-light-md rounded-xxl bg-${value.category.acf.color}-gradiant`}>
                        <h4
                          className="fw-700 font-lg ls-3 text-white mb-0"><span
                          className="ls-3 d-block font-xsss text-white fw-500">{value.monthShort}</span>{value.twoDigitsDay}
                        </h4>
                      </div>
                      <h2 className="fw-700 lh-3 font-xss">{value.title}
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">{value.shortDescription}</span>
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                          <i className="ti-tag me-1"></i>{decodeHtml(value.category.name)}
                        </span>
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                          <i className="ti-location-pin me-1"></i>Web Conference
                        </span>
                      </h2>
                    </div>
                    {
                      value.date < Date.now() ?
                      <span className="event-finished-label">Evento concluso</span>
                        :
                      <span className="event-finished-label">{value.formattedDate} alle ore {value.formattedHour}</span>
                    }
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Events