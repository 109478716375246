import React from 'react';
import ReactDOM from 'react-dom/client';

import './main.scss';
import * as serviceWorker from './serviceWorker';
import {AuthContextProvider} from "./components/AuthContext/AuthContext";
import {RecoilRoot} from "recoil";
import App from "./App";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render((
  <div className="App">
    <RecoilRoot>
      <AuthContextProvider>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </AuthContextProvider>
    </RecoilRoot>
  </div>
))
serviceWorker.register();