import React, {useEffect, useState} from "react";

import './LatestPosts.scss'
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import Load from "../Load";
import WpPost from "../../models/WpPost";
import {Link} from "react-router-dom";

const LatestPosts = () => {
  const wpClient = useRecoilValue(wpClientSelector)
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const pwd = process.env.REACT_APP_WP_SEC_PWD

  useEffect(() => {
    wpClient.posts()
      .categories()
      .perPage(5)
      .page(1)
      .password(pwd)
      .embed()
      .then(function (response) {
        setPosts(response.map(post => new WpPost(post)))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [])

  if (error) {
    return (
      <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
        <div className="card-body d-block p-4">
          <h4 className="fw-700 font-xsss text-grey-900">Ultimi post</h4>
          <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">Si è verificato un errore nel caricamento dei
            posts</p>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <Load/>
    )
  }

  if (posts.length === 0) {
    return (
      <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
        <div className="card-body d-block p-4">
          <h4 className="fw-700 font-xsss text-grey-900">Ultimi post</h4>
          <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">Non sono presenti post</p>
        </div>
      </div>
    )
  }

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 font-xsss text-grey-900">Ultimi post</h4>
      </div>
      {posts
        .map((post, index) => {
          return (
            <div key={index} className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
              <Link to={`/articoli/${post.slug}`}>
                <h3 className="fw-500 text-grey-900 font-xss mt-1">
                  {post.title}
                  <span className="d-block font-xsss fw-500 mt-1 lh-4 text-grey-600"
                        style={{textAlign: "justify"}}
                        dangerouslySetInnerHTML={{__html: post.shortExcerpt}} />
                </h3>
              </Link>
            </div>
          )
        })}
    </div>
  )
}

export default LatestPosts