import {decodeHtml} from "../utils/Escape";

class WpPage {
  constructor(wpPage) {
    this.id = wpPage.id;
    this.slug = wpPage.slug;
    this.content = wpPage.content.rendered
    this.title = decodeHtml(wpPage.title.rendered)
    this.commentsAllowed = wpPage["comment_status"] === "open"
    this.embedded = wpPage["_embedded"]
  }
  get imageUrl() {
    if (!this.embedded["wp:featuredmedia"]) {
      return ""
    }
    return this.embedded["wp:featuredmedia"][0]["source_url"]
  }
}

export default WpPage