import React, {useEffect} from "react";

import './Journal.scss'
import PostView from "../PostView";
import Load from "../Load";
import {useRecoilState, useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import PropTypes from "prop-types";
import WpPost from "../../models/WpPost";
import InfiniteScroll from "react-infinite-scroll-component";
import {errorStateFamily, loadingStateFamily, pageStateFamily, postsStateFamily, totalPagesStateFamily} from "./state";

const Journal = (props) => {
  const pwd = process.env.REACT_APP_WP_SEC_PWD
  const category = props.category
  const user = props.user
  const stickyFirst = props.stickyFirst
  const wpClient = useRecoilValue(wpClientSelector)

  const [posts, setPosts] = useRecoilState(postsStateFamily(category))
  const [loading, setLoading] = useRecoilState(loadingStateFamily(category))
  const [error, setError] = useRecoilState(errorStateFamily(category))
  const [page, setPage] = useRecoilState(pageStateFamily(category))
  const [totalPages, setTotalPages] = useRecoilState(totalPagesStateFamily(category))

  useEffect(() => {
    if (posts.length > 0) {
      return
    }
    setPage(0)
    if (stickyFirst) {
      setLoading(true)
      wpClient.posts()
        .categories(category)
        .sticky(true)
        .perPage(3)
        .password(pwd)
        .embed()
        .then(function (response) {
          setPosts(response.map(post => new WpPost(post)))
          setLoading(false)
          fetchNextPage()
        })
        .catch(error => {
          console.error(error)
          setError(true)
          setLoading(false)
        });
    } else {
      fetchNextPage()
    }
  }, [stickyFirst, category])

  const fetchNextPage = () => {
    wpClient.posts()
      .categories(category)
      .sticky(false)
      .perPage(5)
      .page(page + 1)
      .password(pwd)
      .embed()
      .then(function (response) {
        setTotalPages(response['_paging'].totalPages)
        setPage(page + 1)
        setPosts((current) => [
          ...current,
          ...response.map(post => new WpPost(post))
        ])
      })
      .catch(error => {
        console.error(error)
        setError(true)
      });
  }

  if (error) {
    return (
      <span>Errore!</span>
    )
  }

  if (loading) {
    return (
      <Load/>
    )
  }

  return (
    <div>
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={page < totalPages}
        loader={<Load/>}
        dataLength={posts.length}
        endMessage={
          <p style={{textAlign: "center"}}>
            Per ora è tutto! Non ci sono altri post da visualizzare.
          </p>
        }
        scrollThreshold={0.9}>
        {
          posts.map(post => <PostView key={post.id} post={post} user={user}/>)
        }
      </InfiniteScroll>
    </div>
  )
}

Journal.propTypes = {
  category: PropTypes.number.isRequired,
  user: PropTypes.object,
  stickyFirst: PropTypes.bool.isRequired,
}

export default Journal