import React, {Fragment, useContext, useEffect, useState} from "react";
import './App.scss'

import {Route, Routes, useLocation} from "react-router-dom";
import SecRoute from "./components/SecRoute";
import Home from "./pages/Home";
import Settings from "./pages/Settings";
import Account from "./pages/Account";
import ChangePassword from "./pages/ChangePassword";
import HelpBox from "./pages/HelpBox";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Chat from "./pages/Chat";
import NotFound from "./pages/NotFound";
import AuthContext from "./components/AuthContext";
import {Header, HeaderChat} from "./components/Header";
import PostDetail from "./pages/PostDetail";
import SectionPage from "./pages/SectionPage/SectionPage";
import {useRecoilState, useRecoilValue} from "recoil";
import {wpClientSelector} from "./state/api";
import SearchResult from "./pages/SearchResult";
import EventDetail from "./pages/EventDetail";
import Events from "./pages/Events";
import PageRenderer from "./pages/PageRenderer";
import {userStateSelector} from "./state/auth";
import UserState from "./models/UserState";
import AppFooter from "./components/AppFooter";
import Notifications from "./pages/Notifications";
import WpNotification from "./models/WpNotification";
import AccountPublic from "./pages/AccountPublic";

const App = () => {
  const authCtx = useContext(AuthContext);
  const wpClient = useRecoilValue(wpClientSelector)
  const [user, setUser] = useState(null)
  const [unreadNotifications, setUnreadNotifications] = useState(0)
  const [unreadChats, setUnreadChats] = useState([])
  const [userState, setUserState] = useRecoilState(userStateSelector)
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
    if (!authCtx.isLoggedIn) {
      setLoading(false)
      return
    }
    setLoading(true)
    // fetch user and user state
    wpClient.users().me().embed()
      .then(function (response) {
        setUser(response)
        const userState = new UserState(response.state)
        setUserState(userState)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        authCtx.onLogout()
      });
  }, [authCtx])

  useEffect(() => {
    if (!userState || !userState.posts) {
      return
    }
    updateUnreadNotifications();
    updateUnreadChats();
  }, [userState]);

  const updateUnreadNotifications = () => {
    wpClient.notifications()
      .perPage(10)
      .embed()
      .then(function (response) {
        const unreadNots = response
          .map(not => new WpNotification(not))
          .filter(not => {
            const postState = userState.posts[not.post.id]
            if (!postState) {
              return true
            }
            return !postState.read
          })
          .length
        setUnreadNotifications(unreadNots)
      })
      .catch(error => {
        console.error(error)
      });
  }

  const updateUnreadChats = () => {
    wpClient.chats()
      .perPage(100)
      .param('meta_query[0][key]', 'users')
      .param('meta_query[0][value]', `_${authCtx.data().id}_`)
      .param('meta_query[0][compare]', 'LIKE')
      .then(function (response) {
        const unreadChats = response
          .filter(chat => {
            if (!userState.chats.hasOwnProperty(chat.id)) {
              return true
            }
            const chatState = userState.chats[chat.id]
            if (!chatState) {
              return true
            }
            return chatState.timestamp < chat.acf.timestamp
          })
        setUnreadChats(unreadChats)
      })
      .catch(error => {
        console.error(error)
      });
  }

  if (loading) {
    return (
      <></>
    )
  }

  return (
    <Fragment>
      {
        authCtx.isLoggedIn &&
        (
          location.pathname !== "/chat"
            ? <Header user={user} unreadNotifications={unreadNotifications} unreadChats={unreadChats}/>
            : <HeaderChat user={user} unreadNotifications={unreadNotifications} unreadChats={unreadChats}/>
        )
      }
      <Routes>
        <Route path="/" index element={<SecRoute><Home user={user}/></SecRoute>}/>
        <Route path="/home" index element={<SecRoute><Home user={user}/></SecRoute>}/>
        <Route path="/articoli/:slug" index element={<SecRoute><PostDetail user={user}/></SecRoute>}/>
        <Route path="/sezioni/:slug" index element={<SecRoute><SectionPage user={user}/></SecRoute>}/>
        <Route path="/impostazioni" element={<SecRoute><Settings/></SecRoute>}/>
        <Route path="/impostazioni/account" element={<SecRoute><Account user={user}/></SecRoute>}/>
        <Route path="/impostazioni/password" element={<SecRoute><ChangePassword user={user}/></SecRoute>}/>
        <Route path="/ricerca/:keywords" element={<SecRoute><SearchResult user={user}/></SecRoute>}/>
        <Route path="/eventi" element={<SecRoute><Events/></SecRoute>}/>
        <Route path="/eventi/:slug" element={<SecRoute><EventDetail user={user}/></SecRoute>}/>
        <Route path="/pagine/:slug" element={<SecRoute><PageRenderer user={user}/></SecRoute>}/>
        <Route path="/policy" element={<HelpBox/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/forgot" element={<Forgot/>}/>
        <Route path="/chat" element={<Chat user={user} unreadChats={unreadChats}/>}/>
        <Route path="/notifiche" element={<Notifications user={user}/>}/>
        <Route path="/utenti/:id" element={<AccountPublic/>}/>
        <Route path='*' element={<SecRoute><NotFound/></SecRoute>}/>
      </Routes>
      {authCtx.isLoggedIn &&
        <AppFooter user={user} unreadNotifications={unreadNotifications} unreadChats={unreadChats}/>
      }
    </Fragment>
  )
}

export default App