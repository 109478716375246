import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import {useNavigate} from "react-router-dom";

const HelpBox = () => {
    const approved = localStorage.getItem("22e22_policy_approved") === "true"
    const navigate = useNavigate()
    const approveConditions = () => {
      localStorage.setItem("22e22_policy_approved", "true")
      navigate("/")
    }
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                     style={{backgroundImage: `url("/assets/images/help-bg.png")`}}>
                  <div className="card-body p-md-5 p-4 text-center" style={{backgroundColor: 'rgba(0,85,255,0.8)'}}>
                    <h2 className="fw-700 display2-size text-white display2-md-size lh-2">Benvenuto su 22e22!</h2>
                    <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">
                      Qui sotto puoi trovare le condizioni di utilizzo del portale, che devi aver accettato per poter proseguire
                      con la navigazione di 22e22.<br/>Clicca su ogni sezione per leggerne il contenuto prima di approvare.
                    </p>
                    <br/>
                    <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">
                      <b>
                        Attenzione: prima di procedere è obbligatorio accettare le condizioni mediante il pulsante "Accetta condizioni" che troverai
                        al termine della pagina.
                      </b>
                    </p>
                  </div>
                </div>
                <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                  <Accordion className="accodion-style--1 accordion" preExpanded={'0'}>
                    <AccordionItem dangerouslySetExpanded={true} className="card shadow-xss">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="fw-600 pt-2 pb-2 mb-0">Introduzione</h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="card-body">
                        <p>Benvenuto su 22e22. Questa community editoriale è di proprietà e gestita da Sul Romanzo Agenzia
                          Letteraria, titolare Morgan Palmas. Accedendo a 22e22 e utilizzandolo, accetti di essere vincolato
                          da queste Condizioni di Utilizzo.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="card shadow-xss">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="fw-600 pt-2 pb-2 mb-0">Registrazione e accesso</h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="card-body">
                        <p>Per accedere a 22e22 è necessario registrarsi fornendo un nome utente, un indirizzo e-mail valido e
                          una password. Sei responsabile di mantenere la riservatezza delle tue credenziali d&#39;accesso e di
                          qualsiasi attività che avvenga con il tuo account.</p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className="card shadow-xss">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="fw-600 pt-2 pb-2 mb-0">Uso dei contenuti</h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="card-body">
                        <p>Tutti i contenuti presenti su 22e22 sono di proprietà esclusiva e non possono essere copiati,
                          distribuiti o utilizzati in alcun modo al di fuori della community editoriale 22e22, a meno che non
                          sia esplicitamente consentito dall&#39;autore.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="card shadow-xss">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="fw-600 pt-2 pb-2 mb-0">Comportamento degli utenti</h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="card-body">
                        <p>Gli utenti di 22e22 si impegnano a comportarsi in maniera rispettosa e costruttiva, evitando offese,
                          molestie, discriminazioni e qualsiasi altra forma di comportamento inappropriato.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="card shadow-xss">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="fw-600 pt-2 pb-2 mb-0">Violazioni</h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="card-body">
                        <p>Se si violano queste Condizioni, ci riserviamo il diritto di prendere qualsiasi azione appropriata,
                          compresa la sospensione o l&#39;eliminazione del tuo account.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="card shadow-xss">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="fw-600 pt-2 pb-2 mb-0">Pagamenti e rimborsi</h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="card-body">
                        <p>Per accedere ai contenuti di 22e22 è necessario pagare una quota una tantum. Questa quota
                          permette all&#39;utente di creare un account e di accedere per sempre a tutti i contenuti presenti su
                          22e22. Si prega di notare che questa quota non è rimborsabile.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="card shadow-xss">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="fw-600 pt-2 pb-2 mb-0">Modifiche alle condizioni</h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="card-body">
                        <p>Ci riserviamo il diritto di modificare queste Condizioni in qualsiasi momento, comunicandolo agli
                          utenti. Le modifiche entreranno in vigore immediatamente dopo la loro pubblicazione su 22e22. Se
                          continui a utilizzare 22e22 dopo la pubblicazione delle modifiche, significa che accetti le
                          Condizioni aggiornate.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                {
                  !approved &&
                  <a href="#" onClick={approveConditions} className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">
                    Accetta condizioni
                  </a>
                }
                {
                  approved &&
                  <span>Hai già accettato le condizioni di utilizzo</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default HelpBox;