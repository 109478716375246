import React from "react";

import './AvatarImg.scss'
import PropTypes from "prop-types";

const AvatarImg = (props) => {
  const src = props.src
  const classes = props.classes
  const fallback = "/assets/images/user.png"

  const onError = (el) => {
    el.src = fallback
  }

  return (
    <img
      src={src}
      alt="avatar"
      className={classes + " avatar-img"}
      onError={(event) => onError(event.target)}
    />
  )
}

AvatarImg.propTypes = {
  src: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
}

export default AvatarImg