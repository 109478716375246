
const TimeSince = (date) => {
  const seconds = (new Date().getTime() - new Date(date)) / 1000
  let interval = seconds / 31536000
  if (interval >= 1) {
    return Math.floor(interval) + " ann" + (interval >= 2 ? "i" : "o") + " fa"
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    return Math.floor(interval) + " mes" + (interval >= 2 ? "i" : "e") + " fa"
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    return Math.floor(interval) + " giorn" + (interval >= 2 ? "i" : "o") + " fa"
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    return Math.floor(interval) + " or" + (interval >= 2 ? "e" : "a") + " fa"
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return Math.floor(interval) + " minut" + (interval >= 2 ? "i" : "o") + " fa"
  }
  return Math.floor(seconds) + " secondi fa"
}

export default TimeSince