class WpUser {
  constructor(wpUser) {
    this.id = wpUser.id
    this.username = wpUser.username
    this.first_name = wpUser.first_name
    this.last_name = wpUser.last_name
    this.description = wpUser.description
    this.shortDescription = wpUser.description.length > 100 ? wpUser.description.substring(0, 100) + "..." : wpUser.description
    this.tinyDescription = wpUser.description.length > 30 ? wpUser.description.substring(0, 30) + "..." : wpUser.description
    this.name = this.first_name.trim().length > 0 ? (this.first_name + " " + this.last_name) : wpUser.name
    this.avatarUrls = wpUser["avatar_urls"]
    this.simple_local_avatar = wpUser["simple_local_avatar"]
  }

  get avatarUrl() {
    return this.avatarUrls ? this.avatarUrls[96] : "/assets/images/user.png"
  }

  get avatarUrl48() {
    return this.avatarUrls ? this.avatarUrls[48] : "/assets/images/user.png"
  }

  get avatar() {
    if (this.avatarUrls.length === 0) {
      return "/assets/images/user.png"
    }
    return this.avatarUrls[96]
  }

  get avatarFull() {
    if (!this["simple_local_avatar"] || !this["simple_local_avatar"]["full"] || this["simple_local_avatar"].length === 0) {
      return this.avatar
    }
    return this["simple_local_avatar"]["full"]
  }
}

export default WpUser