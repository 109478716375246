import React, {useState} from 'react';
import PropTypes from "prop-types";

import './PostView.scss'
import {Link} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import AvatarImg from "../AvatarImg";
import PostVideo from "../PostVideo";
import Reactions from "../Reactions";

const PostView = (props) => {
  const post = props.post
  const user = props.user
  const wpClient = useRecoilValue(wpClientSelector)
  const id = post.id

  const [menuOpened, setMenuOpened] = useState(false)
  const menuClass = `${menuOpened ? " show" : ""}`;

  const toggleMenu = () => {
    setMenuOpened(!menuOpened)
  }

  const onSubmitDelete = () => {
    if (user.id !== post.author.id) {
      return
    }
    if (window.confirm("Sei sicuro di voler eliminare questo post?")) {
      wpClient.posts().id(id)
        .delete()
        .then(function () {
          window.location.reload()
        })
        .catch(error => {
          console.error(error)
          window.alert("Si è verificato un errore nel tentativo di eliminare il post. Riprova più tardi")
        });
    }
  }

  return (
    <div className="postView card w-100 rounded-xxl p-4 mb-3">
      <div className="card-body card-body-header gne p-0 d-flex mt-0">
        <figure className="avatar">
          <Link style={{color: "#111"}} to={`/utenti/${post.author.id}`}>
            <AvatarImg src={post.authorAvatar} classes="shadow-sm rounded-circle w45 h45"/>
          </Link>
        </figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          <Link style={{color: "#111"}} to={`/utenti/${post.author.id}`}>{post.author.name}</Link>
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {post.timeSince}</span>
        </h4>
        {
          post.sticky &&
          <div className="pin" title="Questo post è stato messo in evidenza">
            <i className="ti-pin2 text-grey-900 btn-round-md bg-greylight font-xss"></i>
          </div>
        }
        {
          post.author.id === user.id &&
          <>
            <div className={`context-menu pointer ${menuClass}`} id="dropdownMenu4" data-bs-toggle="dropdown"
                 aria-expanded="false"
                 onClick={toggleMenu}>
              <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
            </div>
            <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`}
                 aria-labelledby="dropdownMenu4">
              <div className="card-body p-0 d-flex">
                <i className="feather-trash text-grey-500 me-3 font-lg"></i>
                <a href="#" onClick={onSubmitDelete}>
                  <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                    Elimina post
                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Elimina definitamente questo post dal portale
                  </span>
                  </h4>
                </a>
              </div>
            </div>
          </>
        }
      </div>
      {
        post.videoUrl &&
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
          <PostVideo videoUrl={post.videoUrl} previewImg={post.videoImagePreview}/>
        </div>
      }
      <div className="card-body p-0">
        <Link to={post.detailUrl}>
          <h3>{post.title}</h3>
        </Link>
        {
          <p className="fw-500 text-grey-600 lh-26 font-xsss w-100 mb-2" style={{textAlign: "justify"}}
             dangerouslySetInnerHTML={{__html: post.excerpt}}/>
        }
        <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
          <Link to={post.detailUrl} className="fw-600 text-primary">Leggi</Link>
        </p>
      </div>
      {
        (post.imageUrl && !post.videoUrl) &&
        <div className="card-body d-block p-0 mb-3">
          <div className="row ps-2 pe-2">
            <div className="highlight-image col-sm-12 p-1">
              <Link to={post.detailUrl}>
                <img src={`${post.imageUrl}`} className="rounded-3" alt="post"/>
              </Link>
            </div>
          </div>
        </div>
      }
      <div className="card-body d-flex p-0">
        <Reactions post={post} user={user}/>
        <Link to={post.detailUrl}
              className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
          <i style={{width: "30px"}}
             className="d-flex feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
          <span className="d-none-xss">{post.commentsLabel}</span>
        </Link>
        <div
          className={`post-section-button pointer ms-auto align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss`}>
          {
            (post.category && post.category.slug.length > 0 && post.category.slug !== "sezioni") &&
            <Link to={`/sezioni/${post.category.slug}`}
                  className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
              <div
                className={`section-tag bg-${post.category.acf.color}-gradiant`}>{post.category.name.replace(/&amp;/g, '&')}</div>
            </Link>
          }
        </div>
      </div>
    </div>
  );
}

PostView.propTypes = {
  post: PropTypes.object.isRequired,
  user: PropTypes.object,
}

export default PostView;