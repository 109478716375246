import {atomFamily} from "recoil";

const postsStateFamily = atomFamily({
  key: 'posts_state',
  default: [],
});

const pageStateFamily = atomFamily({
  key: 'page_state',
  default: 0,
});

const loadingStateFamily = atomFamily({
  key: 'loading_state',
  default: false,
});

const errorStateFamily = atomFamily({
  key: 'error_state',
  default: false,
});

const totalPagesStateFamily = atomFamily({
  key: 'totalPages_state',
  default: 10,
});

export {
  postsStateFamily,
  pageStateFamily,
  loadingStateFamily,
  errorStateFamily,
  totalPagesStateFamily
}