import TimeSince from "../utils";
import {decodeHtml} from "../utils/Escape";

class WpPost {
  constructor(wpPost) {
    this.id = wpPost.id
    this.slug = wpPost.slug
    this.sticky = wpPost.sticky
    if (wpPost.content) {
      this.content = wpPost.content.rendered
    }
    this.title = decodeHtml(wpPost.title.rendered)
    this.commentsAllowed = wpPost["comment_status"] === "open"
    this.acf = wpPost.acf
    this.totReactions = wpPost.acf.likes + wpPost.acf.wows + wpPost.acf.hearts + wpPost.acf.sighs + wpPost.acf.ahahs
    if (wpPost["_embedded"]) {
      this.commentsNumber = (wpPost["_embedded"].replies && wpPost["_embedded"].replies[0]) ? wpPost["_embedded"].replies[0].length : 0
      this.author = wpPost["_embedded"].author[0]
      this.embedded = wpPost["_embedded"]
      this.category = fetchPostCategory(wpPost["_embedded"])
    }
    this.excerpt = wpPost.excerpt.rendered
    this.shortExcerpt = this.excerpt.length > 100 ? this.excerpt.substring(0, 100) + "..." : this.excerpt;
    this.timeSince = TimeSince(wpPost.date)
    this.detailUrl = `/articoli/${wpPost.slug}`
    this.videoUrl = wpPost.acf["video_url"]
    this.videoImagePreview = wpPost.acf["image_preview"]
  }
  get authorAvatar() {
    return this.author["avatar_urls"][96]
  }
  get imageUrl() {
    if (!this.embedded["wp:featuredmedia"]) {
      return ""
    }
    return this.embedded["wp:featuredmedia"][0]["source_url"]
  }
  get commentsLabel() {
    if (this.commentsNumber === 1) {
      return this.commentsNumber + " Commento"
    }
    if (this.commentsNumber >= 10) {
      return "10+ Commenti";
    }
    return this.commentsNumber + " Commenti";
  }
}

const fetchPostCategory = (embedded) => {
  if (!embedded["wp:term"]) {
    return ""
  }
  return embedded["wp:term"][0].find(cat => cat.id !== process.env.REACT_APP_SECTIONS_PARENT_CATEGORY_ID)
}

export default WpPost