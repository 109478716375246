import React, {useEffect, useState} from "react";

import './PostDetail.scss'
import {useParams, useNavigate, Link} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import Load from "../../components/Load";
import PostComments from "../../components/PostComments";
import PropTypes from "prop-types";
import WpPost from "../../models/WpPost";
import AvatarImg from "../../components/AvatarImg";
import PostVideo from "../../components/PostVideo";
import Reactions from "../../components/Reactions";
import UserState from "../../models/UserState";
import {userStateSelector} from "../../state/auth";

const PostDetail = (props) => {
  const {slug} = useParams();
  const user = props.user
  const navigate = useNavigate();
  const wpClient = useRecoilValue(wpClientSelector)
  const [userState, setUserState] = useRecoilState(userStateSelector)
  const [post, setPost] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const pwd = process.env.REACT_APP_WP_SEC_PWD

  useEffect(() => {
    setLoading(true)
    setError("")
    wpClient.posts().embed().slug(slug).password(pwd).get()
      .then(function (response) {
        if (!response || response.length === 0) {
          setError("Questo articolo non esiste più oppure è stato spostato.")
          setLoading(false)
          return
        }
        setPost(new WpPost(response[0]))
        setLoading(false)
        markPostAsRead(new WpPost(response[0]))
      })
      .catch(error => {
        console.error(error)
        setError("Non è stato possibile caricare i dettagli dell'articolo.")
        setLoading(false)
      });
  }, [slug])

  const markPostAsRead = (post) => {
    if (userState.posts[post.id] && userState.posts[post.id].read) {
      return
    }
    const newUserState = {
      ...userState,
      posts: {
        ...userState.posts,
        [post.id]: {
          ...userState.posts[post.id],
          read: true,
        }
      }
    }
    const {...plainStateObj} = newUserState
    const req = {
      ...user,
      state: JSON.stringify(plainStateObj)
    }
    return wpClient.users().me().update(req)
      .then(function (response) {
        setUserState(new UserState(response.state))
      })
      .catch(error => {
        console.error(error)
      })
  }

  if (error.length > 0) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="postDetail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="card-body p-0 d-flex">
                    <span>{error}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="postDetail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="card-body p-0 d-flex">
                    <Load/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="row">
            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <p className="back-button" onClick={() => navigate(-1)}><i className="feather-arrow-left"/> Indietro</p>
              <div className="post-detail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                <div className="subtitle-data card-body p-0 d-flex">
                  <h1>{post.title}</h1>
                  <div className="author-info">
                    <figure className="avatar me-3">
                      <Link style={{color: "#111"}} to={`/utenti/${post.author.id}`}>
                        <AvatarImg src={post.authorAvatar} classes="shadow-sm rounded-circle w45 h45"/>
                      </Link>
                    </figure>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                      <Link style={{color: "#111"}} to={`/utenti/${post.author.id}`}>{post.author.name}</Link>
                      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {post.timeSince}</span>
                    </h4>
                  </div>
                </div>
                {
                  post.videoUrl ?
                    <div className="post-highlight-img card-body p-0">
                      <PostVideo videoUrl={post.videoUrl} previewImg={post.videoImagePreview}/>
                    </div>
                    : post.imageUrl ?
                      <div className="post-highlight-img card-body p-0">
                        <a href={post.imageUrl} target="_blank" rel="noreferrer">
                          <img src={post.imageUrl} className="rounded-3" alt="post"/>
                        </a>
                      </div>
                      :
                      ''
                }
                <div className="post-content card-body p-0 me-lg-5" dangerouslySetInnerHTML={{__html: post.content}}/>
                <div
                  className={`post-content-bottom pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss`}>
                  <Reactions post={post} user={user}/>
                  {
                    (post.category && post.category.slug.length > 0 && post.category.slug !== "sezioni") &&
                    <div className="pcb-section-name d-flex align-items-center">
                      <Link to={`/sezioni/${post.category.slug}`}
                            className="fw-600 text-grey-900 text-dark lh-26 font-xssss">
                        <div
                          className={`section-tag bg-${post.category.acf.color}-gradiant`}>{post.category.name.replace(/&amp;/g, '&')}</div>
                      </Link>
                    </div>
                  }
                </div>
              </div>
              {
                post.commentsAllowed && <PostComments postId={post.id} user={user}/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PostDetail.propTypes = {
  user: PropTypes.object,
}

export default PostDetail