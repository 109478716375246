import React, {useEffect, useState} from "react";

import './chat.scss'
import PropTypes from "prop-types";
import {ChatCanvas, ChatTop, ChatUsersList} from "../../components/ChatUserSlider";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";

const Chat = ({user, unreadChats}) => {
  const pwd = process.env.REACT_APP_WP_SEC_CHAT_PWD
  const wpClient = useRecoilValue(wpClientSelector)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [activeChatRecipients, setActiveChatRecipients] = useState([])
  const [recipient, setRecipient] = useState(null)

  useEffect(() => {
    if (user == null) {
      return
    }
    setLoading(true)
    fetchChats()
    const interval = setInterval(() => {
      fetchChats()
    }, 15000);

    return () => clearInterval(interval);
  }, [user]);

  const onChatUserSelected = (recipientUser) => {
    setRecipient(recipientUser)
  }

  const fetchChats = () => {
    wpClient.chats()
      .password(pwd)
      .perPage(100)
      .param('meta_query[0][key]', 'users')
      .param('meta_query[0][value]', `_${user.id}_`)
      .param('meta_query[0][compare]', 'LIKE')
      .then(function (response) {
        setActiveChatRecipients(response.sort((a, b) => b.acf.timestamp - a.acf.timestamp))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }

  if (loading || error) {
    return (
      <></>
    )
  }

  return (
    <div className="col-xl-12 col-xxl-12 col-lg-12 bg-white" style={{marginTop: "60px", height: "100%"}}>
      <div className="chat-page-content w-100 border-0 overflow-hidden">
        {
          recipient &&
          <ChatTop recipient={recipient}/>
        }
        <ChatUsersList
          user={user}
          selectedRecipientId={recipient && recipient.id}
          activeChatRecipients={activeChatRecipients}
          onChatUserSelected={onChatUserSelected}
          unreadChats={unreadChats}/>
        <ChatCanvas recipient={recipient} user={user}/>
      </div>
    </div>
  )
}

Chat.propTypes = {
  user: PropTypes.object.isRequired,
  unreadChats: PropTypes.array.isRequired,
}

export default Chat;