import React, {useEffect, useState} from "react";

import './PageRenderer.scss'
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import WpPage from "../../models/WpPage";
import Load from "../../components/Load";
import PropTypes from "prop-types";
import PostDetail from "../PostDetail";
import PostComments from "../../components/PostComments";

const PageRenderer = (props) => {
  const {slug} = useParams();
  const user = props.user
  const navigate = useNavigate()
  const wpClient = useRecoilValue(wpClientSelector)
  const [page, setPage] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const pwd = process.env.REACT_APP_WP_SEC_PWD

  useEffect(() => {
    wpClient.pages().embed().slug(slug).password(pwd).get()
      .then(function (response) {
        if (!response || response.length === 0) {
          setError(true)
          setLoading(false)
          return
        }
        setPage(new WpPage(response[0]))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [slug]);

  if (error.length > 0) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="postDetail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="card-body p-0 d-flex">
                    <span>Si è verificato un errore durante il caricamento della pagina.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="postDetail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="card-body p-0 d-flex">
                    <Load/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="row">
            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <div className="post-detail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                <p className="back-button" onClick={() => navigate(-1)}><i className="feather-arrow-left"/> Indietro</p>
                <div className="subtitle-data card-body p-0 d-flex">
                  <h1>{page.title}</h1>
                </div>
                <div className="post-content card-body p-0 me-lg-5" dangerouslySetInnerHTML={{__html: page.content}}/>
              </div>
              {
                page.commentsAllowed && <PostComments postId={page.id} user={user}/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PostDetail.propTypes = {
  user: PropTypes.object,
}

export default PageRenderer