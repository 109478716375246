import WpPost from "./WpPost";
import WpUser from "./WpUser";
import TimeSince from "../utils";
import {decodeHtml} from "../utils/Escape";

class WpNotification {
  constructor(wpNotification) {
    this.id = wpNotification.id
    this.title = decodeHtml(wpNotification.title.rendered)
    this.date = wpNotification.date
    this.timeSince = TimeSince(wpNotification.date)
    this.acf = wpNotification.acf
    this.type = wpNotification.acf.type
    switch (this.type) {
      default:
      case "new_post": {
        this.actionText = "ha postato in"
        break
      }
      case "new_comment": {
        this.actionText = "ha commentato in"
        break
      }
      case "mention": {
        this.actionText = "sei stato menzionato in"
      }
    }
    if (wpNotification["_embedded"]) {
      this.embedded = wpNotification["_embedded"]
      this.sourceUser = new WpUser(wpNotification["_embedded"]["acf:user"].find(user => user.id === wpNotification.acf["source_user"]))
      this.post = new WpPost(wpNotification["_embedded"]["acf:post"][0])
      this.category = wpNotification["_embedded"]["acf:term"][0]
      this.slug = this.post.slug
    }
  }
}

export default WpNotification