
const GetCommentAvatar = (comment) => {
  if (comment == null || comment["author_avatar_urls"].length === 0) {
    return "/assets/images/user.png"
  }
  return comment["author_avatar_urls"][96]
}

export {
  GetCommentAvatar
}