import React, {useEffect, useState} from "react";

import './Notifications.scss'
import {useRecoilState, useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import PropTypes from "prop-types";
import {userStateSelector} from "../../state/auth";
import Load from "../../components/Load";
import InfiniteScroll from "react-infinite-scroll-component";
import WpNotification from "../../models/WpNotification";
import AvatarImg from "../../components/AvatarImg";
import {Link} from "react-router-dom";
import {decodeHtml} from "../../utils/Escape";
import UserState from "../../models/UserState";

const Notifications = (props) => {
  const pwd = process.env.REACT_APP_WP_SEC_PWD
  const user = props.user
  const [userState, setUserState] = useRecoilState(userStateSelector)
  const wpClient = useRecoilValue(wpClientSelector)

  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(10)

  useEffect(() => {
    if (!user) {
      return
    }
    fetchNextPage(setLoading, 0)
  }, [user]);

  const fetchNextPage = (updateLoader, currentPage) => {
    wpClient.notifications()
      .password(pwd)
      .perPage(15)
      .page(currentPage + 1)
      .embed()
      .then(function (response) {
        if (response['_paging']) {
          setTotalPages(response['_paging'].totalPages)
        }
        setPage(currentPage + 1)
        setNotifications((current) => [
          ...current,
          ...response.map(not => new WpNotification(not)),
        ])
        if (updateLoader) {
          updateLoader(false)
        }
      })
      .catch(error => {
        console.error(error)
        setError(true)
        if (updateLoader) {
          updateLoader(false)
        }
      });
  }

  if (error) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left">
                <div className="row">
                  <div className="col-lg-12">
                    <h4>Si è verificato un errore durante il caricamento delle notifiche. Riprova più tardi</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left">
                <div className="row">
                  <div className="col-lg-12">
                    <Load/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Notification = (props) => {
    const not = props.notification

    const [read, setRead] = useState(false)
    const readClasses = read ? "" : "bg-lightblue theme-light-bg"

    useEffect(() => {
      const postState = userState.posts[not.post.id]
      if (!postState) {
        return
      }
      setRead(postState.read)
    }, [userState]);

    return (
      <li key={not.id} style={{marginBottom: "0.5rem"}}>
        <Link to={`/articoli/${not.slug}`} className={`d-flex align-items-center p-3 rounded-3 ${readClasses}`}>
          <AvatarImg src={not.sourceUser.avatarUrl48} classes="shadow-sm rounded-circle w45 h45"/>
          <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20">
            <strong>{not.sourceUser.name}</strong> {not.actionText} {decodeHtml(not.category.name)} : {not.title}<span
            className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {not.timeSince}</span>
          </h6>
        </Link>
      </li>
    )
  }

  const markAllAsRead = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    let newUserState = userState
    notifications.forEach(not => {
      newUserState = {
        ...newUserState,
        posts: {
          ...newUserState.posts,
          [not.post.id]: {
            ...newUserState.posts[not.post.id],
            read: true,
          }
        }
      }
    })
    const {...plainStateObj} = newUserState
    const req = {
      ...user,
      state: JSON.stringify(plainStateObj)
    }
    return wpClient.users().me().update(req)
      .then(function (response) {
        setUserState(new UserState(response.state))
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="row">
            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <div className="post-detail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                <div className="p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                  <h1 className="fw-700 mb-4 mt-2 font-md text-grey-900 d-flex align-items-center">Notifiche
                    <a title="Segna tutto come letto" onClick={markAllAsRead} href="#" className="ms-auto btn-round-sm bg-greylight rounded-3">
                      <i className="feather-check-square font-xss text-grey-500"></i>
                    </a>
                  </h1>
                  <ul className="notification-box">
                    <InfiniteScroll
                      next={() => fetchNextPage(null, page)}
                      hasMore={page < totalPages}
                      loader={<Load/>}
                      dataLength={notifications.length}
                      endMessage={
                        <p style={{textAlign: "center"}}>
                          Non ci sono altre notifiche da visualizzare.
                        </p>
                      }
                      scrollThreshold={0.9}>
                      {
                        notifications.map(not => (
                          <Notification key={not.id} notification={not}/>
                        ))
                      }
                    </InfiniteScroll>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Notifications.propTypes = {
  user: PropTypes.object,
}

export default Notifications