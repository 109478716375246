import React, {useEffect, useState} from 'react';

import './chat-user-list.scss'

import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import WpUser from "../../models/WpUser";
import PropTypes from "prop-types";
import {ChatAvatar} from "./index";

const ChatUsersList = ({user, onChatUserSelected, selectedRecipientId, activeChatRecipients = [-1], unreadChats}) => {
  const wpClient = useRecoilValue(wpClientSelector)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [activeChatUsers, setActiveChatUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [searchSubmit, setSearchSubmit] = useState("")

  useEffect(() => {
    setLoading(true)
    wpClient.users()
      .perPage(100)
      .order('desc').orderby('registered_date')
      .then(function (response) {
        const users = response.filter(it => it.id !== user.id).map(user => new WpUser(user))
        setUsers(users)
        setLoading(false)
        setError(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [])

  const matchItem = (item, searchParams) => {
    for (let propertyToLookFor in searchParams) {
      let valueToLookFor = searchParams[propertyToLookFor];
      if (item[propertyToLookFor].toLowerCase().indexOf(valueToLookFor.toLowerCase()) >= 0) {
        return true;
      }
    }
    return false;
  }

  let timerId;
  const debounceFunction = function (func, delay) {
    // Cancels the setTimeout method execution
    clearTimeout(timerId)

    // Executes the func after delay time.
    timerId = setTimeout(func, delay)
  }

  useEffect(() => {
    if (!searchSubmit || searchSubmit.trim().length === 0) {
      setFilteredUsers(users)
      return
    }
    setFilteredUsers(
      users
        .filter(user => {
          return matchItem(user, {
            "first_name": searchSubmit,
            "last_name": searchSubmit,
            "name": searchSubmit,
          });
        })
    )
  }, [searchSubmit, users]);

  useEffect(() => {
    if (!filteredUsers || filteredUsers.length === 0) {
      return
    }
    setActiveChatUsers(
      activeChatRecipients
        .map(chat => filteredUsers.find(user => user.id === Number(extractRecipientFromChatAcf(chat))))
    )
  }, [activeChatRecipients, filteredUsers]);

  const extractRecipientFromChatAcf = (chat) => {
    return chat.acf.users
      .replace(`_${user.id}_`, "")
      .replaceAll(`_`, "")
  }

  if (loading || error) {
    return <></>
  }

  const onChatAvatarClick = (user) => {
    onChatUserSelected(user)
  }

  const ChatAvatarList = ({list}) => {
    ChatAvatarList.propTypes = {
      list: PropTypes.array.isRequired,
    }

    return (
      list
        .map((value, index) => (
          <ChatAvatar
            key={index}
            url={value.avatarFull}
            shortBio={value.tinyDescription}
            displayName={value.name}
            userId={value.id}
            isSelected={selectedRecipientId === value.id}
            clickCallback={() => onChatAvatarClick(value)}
            isUnread={unreadChats
              .map(chat => Number(extractRecipientFromChatAcf(chat)))
              .find(recId => value.id === recId) !== undefined}
          />
        ))
    )
  }

  return (
    <div className="chat-user-list scroll-bar">
      <div className="chat-user-list-heading mt-3">
        <h1>Chat</h1>
      </div>
      <form className="chat-user-list-search mt-3 mb-3" onSubmit={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
      }}>
        <input type="text" placeholder="Cerca un utente.." className="font-xssss fw-500"
               onChange={(ev) => debounceFunction(() => setSearchSubmit(ev.target.value), 500)}
        />
      </form>
      <ChatAvatarList list={activeChatUsers.filter(value => value !== undefined)}/>
      <ChatAvatarList list={filteredUsers.filter(
        user =>
          !activeChatRecipients
            .map(chat => Number(extractRecipientFromChatAcf(chat)))
            .includes(user.id)
      )}/>
    </div>
  )
}

ChatUsersList.propTypes = {
  user: PropTypes.object.isRequired,
  onChatUserSelected: PropTypes.func.isRequired,
  selectedRecipientId: PropTypes.number,
  activeChatRecipients: PropTypes.array,
  unreadChats: PropTypes.array.isRequired,
}

export default ChatUsersList;