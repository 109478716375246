import React, {useEffect, useState} from 'react';

import './Events.scss'

import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import Load from "../Load";
import {Link} from "react-router-dom";
import WpEvent from "../../models/WpEvent";
import PropTypes from "prop-types";

const Events = (props) => {
  const wpClient = useRecoilValue(wpClientSelector)
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const shownEventsNumber = 6
  const category = props.category
  const pwd = process.env.REACT_APP_WP_SEC_PWD

  useEffect(() => {
    wpClient.events()
      .categories(category ? category.id : {})
      .perPage(100)
      .password(pwd)
      .embed()
      .then(function (response) {
        setEvents(response.map(event => new WpEvent(event)))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [])

  if (error) {
    return (
      <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
        <div className="card-body d-block p-4">
          <h4 className="fw-700 font-xsss text-grey-900">Eventi</h4>
          <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">Si è verificato un errore nel caricamento degli
            eventi</p>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <Load/>
    )
  }

  if (events.length === 0) {
    return (
      <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
        <div className="card-body d-block p-4">
          <h4 className="fw-700 font-xsss text-grey-900">Eventi</h4>
          <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">Non ci sono eventi programmati al momento</p>
        </div>
      </div>
    )
  }

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 font-xsss text-grey-900">Eventi</h4>
        <Link to="/eventi" className="fw-600 ms-auto font-xssss text-primary">Vedi tutti</Link>
      </div>
      {events
        .filter(evt => evt.date >= Date.now())
        .sort((a, b) => (a.date > b.date) ? 1 : -1)
        .slice(0, shownEventsNumber)
        .map((event, index) => {
          return (
            <div key={event.id} className={`card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden`}>
              <div style={{maxHeight: "70px", width: "62px", minWidth: "62px", minHeight: "70px"}}
                   className={`me-2 p-3 rounded-xxl bg-${event.category.acf.color}-gradiant`}>
                <h2 style={{textAlign: "center"}} className="fw-700 ls-3 lh-1 text-white mb-0">
                  <span className="ls-1 d-block font-xssss text-white fw-600">{event.monthShort}</span>
                  {event.twoDigitsDay}
                </h2>
              </div>
              <Link to={`/eventi/${event.slug}`}>
                <h3 className="fw-500 text-grey-900 font-xss mt-1">{event.title}
                  <span className="d-block font-xsss fw-500 mt-1 lh-4 text-grey-600"
                        style={{textAlign: "justify"}}>
                    {event.shortDescription}
                  </span>
                </h3>
              </Link>
            </div>
          )
        })}
    </div>
  );
}

Events.propTypes = {
  category: PropTypes.object,
}

export default Events;