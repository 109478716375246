import React from "react";
import { Player } from 'video-react';

import './PostVideo.scss'
import PropTypes from "prop-types";

const PostVideo = (props) => {
  const videoUrl = props.videoUrl
  const previewImgUrl = props.previewImg
  return (
    <Player
      playsInline
      poster={previewImgUrl}
      src={videoUrl}
    />
  )
}

PostVideo.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  previewImg: PropTypes.string.isRequired,
}

export default PostVideo