import {selector} from "recoil";
import WPAPI from "wpapi";
import {authState} from "./auth";

const wpClientSelector = selector({
  key: 'wpClientSelector',
  get: ({get}) => {
    const auth = get(authState);
    if (auth == null) {
      return
    }
    const wp = new WPAPI({
      endpoint: process.env.REACT_APP_WP_JSON,
    });
    wp.setHeaders('Authorization', 'Bearer ' + auth.token);
    wp.events = wp.registerRoute('wp/v2', '/eventi/(?P<id>)', {
      params: ['categories', 'order', 'orderby', 'before', 'after', 'password'],
    });
    wp.notifications = wp.registerRoute('wp/v2', '/notifiche/(?P<id>)', {
      params: ['tag', 'before', 'after', 'password'],
    });
    wp.chats = wp.registerRoute('wp/v2', '/chat/(?P<id>)', {
      params: ['password', 'meta_query[0][key]', 'meta_query[0][value]', 'meta_query[0][compare]',
        'meta_query[relation]', 'meta_query[1][key]', 'meta_query[1][value]', 'meta_query[1][compare]'],
    });
    return wp
  },
});

export {wpClientSelector}