import React, {useContext, useState} from "react";
import {Link} from 'react-router-dom';
import axios from "axios";
import PropTypes from "prop-types";
import AuthContext from "../components/AuthContext";

const ChangePassword = (props) => {
  const user = props.user
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
  const authCtx = useContext(AuthContext);

  const onSubmit = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    if (newPassword !== newPasswordConfirm) {
      alert("La nuova password e la conferma non coincidono. Si prega di verificare")
      return
    }
    setLoading(true)
    axios
      .post(process.env.REACT_APP_HOME_URL + 'changepassword', {
        user_id: user.id,
        password: password,
        new_password: newPassword,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function () {
        authCtx.onLogout()
      })
      .catch(function () {
        authCtx.onLogout()
      });
  }

  return (
    <div className="main-content theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                <Link to="/impostazioni" className="d-inline-block mt-2"><i
                  className="ti-arrow-left font-sm text-white"></i></Link>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Cambia password</h4>
              </div>
              <div className="card-body p-lg-5 p-4 w-100 border-0">
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss">Password attuale</label>
                        <input required type="password" name="password" className="form-control"
                               onChange={(ev) => setPassword(ev.target.value)}/>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss">Nuova password</label>
                        <input required type="password" name="new-password" className="form-control"
                               onChange={(ev) => setNewPassword(ev.target.value)}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss">Conferma nuova password</label>
                        <input required type="password" name="new-password-confirm" className="form-control"
                               onChange={(ev) => setNewPasswordConfirm(ev.target.value)}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-0">
                      <button type="submit"
                              disabled={loading}
                              className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">
                        {loading ? "Attendi..." : "Salva"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ChangePassword.propTypes = {
  user: PropTypes.object,
}

export default ChangePassword;