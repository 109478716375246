import React, {useEffect, useState} from "react";

import './Reactions.scss'

import {useRecoilState, useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import PropTypes from "prop-types";
import WpPost from "../../models/WpPost";
import UserState from "../../models/UserState";
import {userStateSelector} from "../../state/auth";

const Reactions = (props) => {
  const postProp = props.post
  const user = props.user
  const emojiKeys = ["likes", "hearts", "wows", "ahahs", "sighs"]

  const wpClient = useRecoilValue(wpClientSelector)
  const [userState, setUserState] = useRecoilState(userStateSelector)
  const [emojiOpened, setEmojiOpened] = useState(false)
  const emojiClass = `${emojiOpened ? " active" : ""}`;
  const [post, setPost] = useState(null)
  const [saving, setSaving] = useState(false)
  const [emojiSelected, setEmojiSelected] = useState()

  useEffect(() => {
    if (!postProp) {
      return
    }
    setPost(postProp)
  }, [postProp]);

  useEffect(() => {
    // interpolate user state with current post
    if (userState.posts[postProp.id]) {
      const postState = userState.posts[postProp.id]
      let updated = false
      Object.keys(postState).forEach(function (key) {
        if (emojiKeys.includes(key) && postState[key] === true) {
          setEmojiSelected(key)
          updated = true
        }
      });
      if (!updated && emojiSelected) {
        setEmojiSelected(null)
      }
    }
  }, [userState]);

  const toggleEmoji = () => {
    setEmojiOpened(!emojiOpened)
  }

  const updateUserState = (acf_field, set) => {
    const newUserState = {
      ...userState,
      posts: {
        ...userState.posts,
        [post.id]: {
          ...userState.posts[post.id],
          [acf_field]: set,
        }
      }
    }
    if (emojiSelected) {
      newUserState.posts[post.id][emojiSelected] = false
    }
    const {...plainStateObj} = newUserState
    const req = {
      ...user,
      state: JSON.stringify(plainStateObj)
    }
    return wpClient.users().me().update(req)
  }

  const react = (acf_field) => {
    if (saving) {
      return
    }
    const adding = acf_field !== emojiSelected ? 1 : -1
    const req = {
      acf: {
        ahahs: post.acf.ahahs,
        hearts: post.acf.hearts,
        likes: post.acf.likes,
        sighs: post.acf.sighs,
        wows: post.acf.wows,
      }
    }
    req.acf[acf_field] += adding
    if (emojiSelected && emojiSelected !== acf_field) {
      req.acf[emojiSelected] -= 1
    }
    setSaving(true)
    updateUserState(acf_field, adding > 0)
      .then(function (response) {
        setUserState(new UserState(response.state))
        wpClient.posts().id(post.id)
          .embed()
          .update(req)
          .then(function (response) {
            setPost(new WpPost(response))
            setEmojiOpened(false)
            setSaving(false)
          })
          .catch(error => {
            setEmojiOpened(false)
            setSaving(false)
            console.error(error)
          })
      })
      .catch(error => {
        setEmojiOpened(false)
        setSaving(false)
        console.error(error)
      })
  }

  if (post == null) {
    return <></>
  }

  return (
    <>
      <div
        className="emoji-section emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-3"
        onClick={toggleEmoji}>
        {
          post.acf.likes > 0 && <i className="em emoji-like me-1 btn-round-xs font-xss"></i>
        }
        {
          post.acf.hearts > 0 && <i className="em emoji-heart me-1 btn-round-xs font-xss"></i>
        }
        {
          post.acf.wows > 0 && <i className="em emoji-wow me-1 btn-round-xs font-xss"></i>
        }
        {
          post.acf.ahahs > 0 && <i className="em emoji-ahah me-1 btn-round-xs font-xss"></i>
        }
        {
          post.acf.sighs > 0 && <i className="em emoji-sigh me-1 btn-round-xs font-xss"></i>
        }
        {
          post.totReactions > 0
            ? <span className="ms-2">{(post.totReactions + " Reazion" + (post.totReactions === 1 ? "e" : "i"))}</span>
            : <><i
              className="feather-thumbs-up text-dark text-grey-900 btn-round-sm font-lg"></i><span>Mi piace</span></>
        }
      </div>
      <div className={`emoji-wrap ${emojiClass}`}>
        {
          saving
            ? <div style={{padding: "12px 10px 1px 15px", textAlign: "center"}}>
              <div className="dot-typing"></div>
            </div>
            : <ul className="emojis list-inline mb-0">
              <li className={`emoji pointer list-inline-item ${emojiSelected === "likes" && "active"}`} title="Mi piace"
                  onClick={() => react("likes")}>
                <i className="em emoji-like"></i>
              </li>
              <li className={`emoji pointer list-inline-item ${emojiSelected === "hearts" && "active"}`} title="Lo adoro!"
                  onClick={() => react("hearts")}>
                <i className="em emoji-heart"></i>
              </li>
              <li className={`emoji pointer list-inline-item ${emojiSelected === "wows" && "active"}`} title="Wow!"
                  onClick={() => react("wows")}>
                <i className="em emoji-wow"></i>
              </li>
              <li className={`emoji pointer list-inline-item ${emojiSelected === "ahahs" && "active"}`}
                  title="Divertente!" onClick={() => react("ahahs")}>
                <i className="em emoji-ahah"></i>
              </li>
              <li className={`emoji pointer list-inline-item ${emojiSelected === "sighs" && "active"}`} title="Triste"
                  onClick={() => react("sighs")}>
                <i className="em emoji-sigh"></i>
              </li>
            </ul>
        }
      </div>
    </>
  )
}

Reactions.propTypes = {
  post: PropTypes.object.isRequired,
  user: PropTypes.object,
}

export default Reactions