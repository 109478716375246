import React, {useEffect} from "react";

import './ChatTop.scss'
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const ChatTop = ({recipient}) => {
  const fallback = "/assets/images/user.png"

  useEffect(() => {
    if (!recipient) {
      return
    }
  }, [recipient]);

  const onError = (el) => {
    el.src = fallback
  }

  return (
    <div className="chat-top">
      <div className="chat-top-dummy"/>
      <div className="chat-top-recipient-details chat-avatar-card">
        <div className="d-flex">
          <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w75 z-index-1">
            <img
              onError={(ev) => onError(ev.target)}
              crossOrigin=""
              src={recipient.avatarFull} alt="avatar"
              className="chat-avatar-img bg-white rounded-circle"/>
          </figure>
          <div className="chat-avatar-info">
            <Link to={`/utenti/${recipient.id}`}>
              <span className="chat-avatar-info-name">{recipient.name}</span>
            </Link>
            <span className="chat-avatar-info-history">{recipient.shortDescription}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

ChatTop.propTypes = {
  recipient: PropTypes.object.isRequired,
}

export default ChatTop