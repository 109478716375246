import React, {useEffect, useState} from "react";

import './SectionPage.scss'
import CreatePost from "../../components/CreatePost";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import Journal from "../../components/Journal";
import SectionProfileCard from "../../components/SectionProfileCard";
import SectionProfileDetail from "../../components/SectionProfileDetail";
import Load from "../../components/Load";
import Events from "../../components/Events";
import PropTypes from "prop-types";

const SectionPage = (props) => {
  const {slug} = useParams();
  const wpClient = useRecoilValue(wpClientSelector)
  const [category, setCategory] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const user = props.user
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    setError("")
    wpClient.categories().slug(slug).embed().get()
      .then(function (response) {
        if (!response || response.length === 0) {
          setError("Questa sezione non esiste oppure è stata spostata.")
          setLoading(false)
          return
        }
        setCategory(response[0])
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError("Non è stato possibile caricare la sezione.")
        setLoading(false)
      });
  }, [slug])

  if (error.length > 0) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="postDetail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="card-body p-0 d-flex">
                    <span>{error}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="postDetail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="card-body p-0 d-flex">
                    <Load/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <p style={{marginBottom: "0"}} className="back-button" onClick={() => navigate(-1)}><i
              className="feather-arrow-left"/> Indietro</p>
            <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
              <SectionProfileCard category={category}/>
              <SectionProfileDetail category={category}/>
              <Events category={category}/>
            </div>
            <div className="col-xl-8 col-xxl-9 col-lg-8 mt-3">
              {
                category.acf.post_creation_enabled && <CreatePost user={user} category={category}/>
              }
              <Journal category={category.id} user={user} stickyFirst={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SectionPage.propTypes = {
  user: PropTypes.object,
}

export default SectionPage