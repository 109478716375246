import React from "react";

import './chat-avatar.scss'
import PropTypes from "prop-types";

const ChatAvatar = ({userId, displayName, url, clickCallback, isSelected, shortBio, isUnread}) => {
  const fallback = "/assets/images/user.png"

  const onError = (el) => {
    el.src = fallback
  }

  return (
    <div
      className={`chat-avatar-card card overflow-hidden cursor-pointer ${isSelected && "chat-avatar-selected"} ${isUnread && " chat-avatar-unread"}`}
      onClick={clickCallback}>
      <div className="d-flex">
        <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w75 z-index-1">
          <img
            onError={(ev) => onError(ev.target)}
            crossOrigin=""
            src={url} alt="avatar"
            className="chat-avatar-img bg-white rounded-circle"/>
        </figure>
        <div className="chat-avatar-info">
          <span className="chat-avatar-info-name">{displayName}</span>
          <span className="chat-avatar-info-history">{shortBio}</span>
        </div>
      </div>
    </div>
  )
}

ChatAvatar.propTypes = {
  userId: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  clickCallback: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isUnread: PropTypes.bool.isRequired,
}

export default ChatAvatar