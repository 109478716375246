import React, {useEffect, useState} from "react";

import './EventDetail.scss'
import {Link, useNavigate, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import Load from "../../components/Load";
import PostComments from "../../components/PostComments";
import PropTypes from "prop-types";
import WpEvent from "../../models/WpEvent";

const EventDetail = (props) => {
  const {slug} = useParams();
  const user = props.user
  const navigate = useNavigate()
  const wpClient = useRecoilValue(wpClientSelector)
  const [event, setEvent] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const pwd = process.env.REACT_APP_WP_SEC_PWD

  useEffect(() => {
    wpClient.events().embed().password(pwd).slug(slug).get()
      .then(function (response) {
        console.log(new WpEvent(response[0]))
        setEvent(new WpEvent(response[0]))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [])

  if (error) {
    return (
      <span>Non è stato possibile caricare i dettagli dell'evento</span>
    )
  }

  if (loading) {
    return (
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="eventDetail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="card-body p-0 d-flex">
                    <Load/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="row">
            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <p className="back-button" onClick={() => navigate(-1)}><i className="feather-arrow-left" /> Indietro</p>
              <div className="event-detail card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                <div className="subtitle-data card-body p-0 d-flex">
                  <h1>{event.title}</h1>
                  <div className="author-info">
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                      <span className="d-block font-xss fw-500 lh-3 text-grey-500"> {event.formattedDate} alle ore {event.formattedHour}</span>
                    </h4>
                  </div>
                </div>
                {
                  event.imageUrl &&
                  <div className="event-highlight-img card-body p-0">
                    <a href={event.imageUrl} target="_blank" rel="noreferrer">
                      <img src={event.imageUrl} className="rounded-3" alt="post"/>
                    </a>
                  </div>
                }
                <div className="post-content card-body p-0 me-lg-5" dangerouslySetInnerHTML={{__html: event.content}} />
                <div className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss`}>
                  {
                    (event.category && event.category.slug.length > 0 && event.category.slug !== "sezioni") &&
                    <Link to={`/sezioni/${event.category.slug}`}
                          className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
                      <div
                        className={`section-tag bg-${event.category.acf.color}-gradiant`}>{event.category.name.replace(/&amp;/g, '&')}</div>
                    </Link>
                  }
                </div>
              </div>
              {
                event.commentsAllowed && <PostComments postId={event.id} user={user} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EventDetail.propTypes = {
  user: PropTypes.object,
}

export default EventDetail