import {decodeHtml} from "../utils/Escape";

class WpEvent {
  constructor(wpEvent) {
    this.id = wpEvent.id;
    this.slug = wpEvent.slug;
    this.date = new Date(wpEvent.acf["event_date"])
    this.content = wpEvent.content.rendered
    this.title = decodeHtml(wpEvent.title.rendered)
    this.shortDescription = wpEvent.acf["short_description"]
    this.commentsAllowed = wpEvent["comment_status"] === "open"
    this.embedded = wpEvent["_embedded"]
    this.category = fetchCategory(this.embedded)
    this.imageUrl = wpEvent.acf["featured_event_image"]
  }
  get monthShort() {
    return this.date.toLocaleString('it-IT', {month: 'short'})
  }
  get twoDigitsDay() {
    return this.date.toLocaleString('it-IT', {day: '2-digit'})
  }
  get formattedDate() {
    return this.date.toLocaleString('it-IT', {month: 'long', day: '2-digit'})
  }
  get formattedHour() {
    return this.date.toLocaleString('it-IT', {hour: '2-digit', minute: '2-digit'})
  }
}

const fetchCategory = (embedded) => {
  if (!embedded["wp:term"]) {
    return ""
  }
  return embedded["wp:term"][0].find(cat => cat.id !== process.env.REACT_APP_SECTIONS_PARENT_CATEGORY_ID)
}

export default WpEvent