import React, {useState} from 'react';

import './header-chat.scss'

import {Link, useNavigate} from 'react-router-dom';
import PropTypes from "prop-types";
import {GetUserAvatar} from "../../utils/User";
import AvatarImg from "../AvatarImg";

const HeaderChat = (props) => {
  const [state, setState] = useState({
    isOpen: false,
    isActive: false,
    isNoti: false,
  })
  const user = props.user
  const unreadNotifications = props.unreadNotifications
  const unreadChats = props.unreadChats
  const navigate = useNavigate()

  const onSearchSubmit = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    const search = ev.target.value
    if (search.length === 0) {
      return
    }
    // remove focus from input and clean the content
    ev.target.blur()
    ev.target.value = ""
    // navigate to search result
    navigate("/ricerca/" + encodeURIComponent(search))
  }

  const toggleActive = () => setState({...state, isActive: !state.isActive});

  const searchClass = `${state.isActive ? " show" : ""}`;

  return (
    <div className="nav-header bg-white only-shadow-xss border-0">
      <div className="nav-top bg-white">
        <Link to="/"><i className="feather-book display2-size me-3 ms-0"></i><span
          className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">22e22</span>
        </Link>
        <span onClick={toggleActive} className="me-2 ms-auto menu-search-icon mob-menu"><i
          className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i></span>
      </div>
      <form onSubmit={onSearchSubmit} className="float-left header-search bg-white">
        <div className="form-group mb-0 icon-input">
          <i className="feather-search font-sm text-grey-400"></i>
          <input
            type="text" placeholder="Cerca"
            className="search-input bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 shadow-xss w350 theme-dark-bg"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                onSearchSubmit(ev)
              }
            }}/>
        </div>
      </form>
      <Link to="/" className="p-2 text-center ms-auto menu-icon">
        <i className="feather-home font-xl text-current" />
      </Link>
      <Link to="/notifiche" className="p-2 pointer text-center ms-3 menu-icon">
        <i className="feather-bell font-xl text-current position-relative">
          {
            unreadNotifications > 0 &&
            <div className="notification-tag" />
          }
        </i>
      </Link>
      <Link to="/chat" className="p-2 text-center ms-3 menu-icon chat-active-btn">
        <i className="feather-message-square font-xl text-current position-relative">
          {
            unreadChats.length > 0 &&
            <div className="notification-tag" />
          }
        </i>
      </Link>
      <Link to="/impostazioni" className="p-0 ms-3 menu-icon">
        <figure title="Visualizza profilo" className="avatar" style={{marginBottom: "0"}}>
          <AvatarImg src={GetUserAvatar(user)} classes="shadow-sm rounded-circle w45 h45"/>
        </figure>
      </Link>
      <div className={`app-header-search ${searchClass}`}>
        <form onSubmit={onSearchSubmit} className="search-form">
          <div className="form-group searchbox mb-0 border-0 p-1">
            <input
              type="text" className="form-control border-0" placeholder="Cerca..."
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  onSearchSubmit(ev)
                }
              }}/>
            <i className="input-icon">
              <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
            </i>
            <span className="ms-1 mt-1 d-inline-block close searchbox-close">
              <i className="ti-close font-xs" onClick={toggleActive}></i>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

HeaderChat.propTypes = {
  user: PropTypes.object,
  unreadNotifications: PropTypes.number.isRequired,
  unreadChats: PropTypes.array.isRequired,
}

export default HeaderChat;