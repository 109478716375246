import React from "react";

import Journal from "../components/Journal";
import Events from "../components/Events";
import PropTypes from "prop-types";
import LatestPosts from "../components/LatestPosts";

const Home = (props) => {
  const topCategory = Number(process.env.REACT_APP_SECTIONS_PARENT_CATEGORY_ID)
  const user = props.user

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="row feed-body">
            <div className="col-xl-8 col-xxl-9 col-lg-8">
              <Journal category={topCategory} user={user} stickyFirst={true} />
            </div>
            <div className="right-navigation col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
              <Events/>
              <LatestPosts/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Home.propTypes = {
  user: PropTypes.object,
}

export default Home;