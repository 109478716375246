import React, {useEffect, useState} from "react";

import './Account.scss'

import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import Load from "../../components/Load";
import {GetUserAvatarFull} from "../../utils/User";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import AvatarImg from "../../components/AvatarImg";
import ImageCropper from "../../components/ImageCropper";
import {ColorExtractor} from "react-color-extractor";

const Account = (props) => {
  const user = props.user
  const [editUser, setEditUser] = useState()
  const wpClient = useRecoilValue(wpClientSelector)
  const [saving, setSaving] = useState(false)
  const [avatar, setAvatar] = useState()
  const [bgColor, setBgColor] = useState("#7f7f7f")

  useEffect(() => {
    setEditUser(user)
  }, [user])

  if (editUser == null) {
    return <Load/>
  }

  const onSubmit = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    setSaving(true)
    if (avatar) {
      wpClient.media()
        .file(avatar)
        .create({
          title: user.slug + '_avatar',
          alt_text: user.slug,
          caption: user.slug,
          description: user.slug + ' avatar'
        })
        .then(function (response) {
          updateUser(response.id)
        })
        .catch(error => {
          console.error(error)
          setSaving(false)
        });
    } else {
      updateUser()
    }
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  const updateUser = (avatarMediaId) => {
    let postUser = editUser
    if (avatarMediaId) {
      postUser["simple_local_avatar"] = {
        "media_id": avatarMediaId
      }
    }
    postUser["first_name"] = capitalizeFirstLetter(postUser["first_name"])
    postUser["last_name"] = capitalizeFirstLetter(postUser["last_name"])
    postUser["name"] = postUser["first_name"] + " " + postUser["last_name"]
    wpClient.users().id(user.id).update(postUser)
      .then(function (response) {
        setSaving(false)
        window.location.reload()
      })
      .catch(error => {
        console.error(error)
        setSaving(false)
      });
  }

  const onAvatarSelected = (file) => {
    setAvatar(file)
  }

  const getColors = colors => {
    setBgColor(colors[0]);
  }

  return (
    <div className="main-content theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              {
                saving &&
                <div className="saving-overlay">
                  <img src="/assets/images/preloader.svg"/>
                  <h1>
                    Attendi, l'operazione potrebbe richiedere qualche istante
                  </h1>
                  <h3>
                    Non ricaricare la pagina.
                  </h3>
                </div>
              }
              <div className="card-body p-4 w-100 border-0 d-flex rounded-3" style={{backgroundColor: `${bgColor}`}}>
                <Link to="/impostazioni" className="d-inline-block mt-2"><i
                  className="ti-arrow-left font-sm text-white"></i></Link>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Modifica profilo</h4>
              </div>
              <div className="card-body p-lg-5 p-4 w-100 border-0 position-relative">
                <div className="public-profile-view-btn row">
                  <Link title="Visualizza profilo pubblico" to={`/utenti/${user.id}`}>
                    <i className="feather-eye"/>
                  </Link>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-4 text-center">
                    <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                      <ColorExtractor src={GetUserAvatarFull(user)} rgb={false} getColors={getColors} onError={() => {
                      }}/>
                      <AvatarImg src={GetUserAvatarFull(editUser)} classes="shadow-sm rounded-3 w-100"/>
                    </figure>
                    <h2 className="fw-700 font-sm text-grey-900 mt-3">{editUser.name}</h2>
                    <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">@{editUser.slug}</h4>
                  </div>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">Username</label>
                      <input type="text" required className="form-control" readOnly value={editUser.slug}
                             onChange={() => {
                             }}/>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">Email</label>
                      <input type="text" required className="form-control" readOnly value={editUser.user_email}
                             onChange={() => {
                             }}/>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">Nascondi email agli altri utenti</label>
                      <input
                        className="m-lg-2"
                        type="checkbox" checked={editUser.hide_email === "1"}
                        onChange={(ev) => setEditUser({...editUser, hide_email: ev.target.checked ? "1" : "0"})}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Nome</label>
                        <input type="text" required className="form-control" value={editUser.first_name}
                               onChange={(ev) => setEditUser({...editUser, first_name: ev.target.value})}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Cognome</label>
                        <input type="text" required className="form-control" value={editUser.last_name}
                               onChange={(ev) => setEditUser({...editUser, last_name: ev.target.value})}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">Facebook</label>
                      <input
                        type="text" className="form-control" value={editUser.social_facebook}
                        onChange={(ev) => setEditUser({...editUser, social_facebook: ev.target.value})}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">Instagram</label>
                      <input
                        type="text" className="form-control" value={editUser.social_instagram}
                        onChange={(ev) => setEditUser({...editUser, social_instagram: ev.target.value})}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">Twitter</label>
                      <input
                        type="text" className="form-control" value={editUser.social_twitter}
                        onChange={(ev) => setEditUser({...editUser, social_twitter: ev.target.value})}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">Linkedin</label>
                      <input
                        type="text" className="form-control" value={editUser.social_linkedin}
                        onChange={(ev) => setEditUser({...editUser, social_linkedin: ev.target.value})}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">Website</label>
                      <input
                        type="text" className="form-control" value={editUser.social_website}
                        onChange={(ev) => setEditUser({...editUser, social_website: ev.target.value})}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="mont-font fw-600 font-xsss mb-2 text-dark">Biografia</label>
                    <textarea className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5"
                              placeholder="Descrivi chi sei e le tue passioni..." value={editUser.description}
                              onChange={(ev) =>
                                setEditUser({...editUser, description: ev.target.value})}/>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="card mt-3 border-0">
                      <div className="card-body d-flex justify-content-between align-items-end p-0">
                        <div className="form-group mb-0 w-100">
                          <label className="mont-font fw-600 font-xsss mb-2 text-dark">Immagine profilo</label>
                          <ImageCropper onCropTentative={onAvatarSelected}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{marginTop: "30px"}} className="col-lg-12">
                    <button
                      type="submit"
                      disabled={saving}
                      className="save-account-btn bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">
                      Salva
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Account.propTypes = {
  user: PropTypes.object,
}

export default Account;