import React, {useState} from 'react';

import './CreatePost.scss'

import PropTypes from "prop-types";
import {GetUserAvatar} from "../../utils/User";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import AvatarImg from "../AvatarImg";

const CreatePost = (props) => {
  const user = props.user
  const category = props.category
  const contentMinLength = 30
  const titleMinLength = 10
  const wpClient = useRecoilValue(wpClientSelector)
  const [saving, setSaving] = useState(false)
  const [content, setContent] = useState("")
  const [title, setTitle] = useState("")
  const [featuredImage, setFeaturedImage] = useState()
  const pwd = process.env.REACT_APP_WP_SEC_PWD

  const onFeatureImageSelected = (ev) => {
    const fileInput = ev.target
    if (fileInput.files.length === 0) {
      return
    }
    const file = fileInput.files[0]
    // max file size upload is 2MB
    if (file.size > 2000000) {
      alert("L'immagine che hai selezionato è troppo grande (max 2MB)")
      return
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      alert("Il file selezionato non è un'immagine valida (i formati consentiti sono JPEG e PNG)")
      return
    }
    setFeaturedImage(file)
  }

  const onSubmitPost = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    if (!isPostValid()) {
      alert("Il titolo deve essere lungo almeno 10 caratteri e il contenuto del post deve contenere almeno 30 caratteri")
      return
    }
    setSaving(true)
    if (featuredImage) {
      const mediaName = user.slug + '_' + category.name.replace(/&amp;/g, '&') + '_post'
      wpClient.media()
        .file(featuredImage)
        .create({
          title: mediaName,
          alt_text: mediaName,
          caption: mediaName,
          description: mediaName
        })
        .then(function (response) {
          createPost(response.id)
        })
        .catch(error => {
          console.error(error)
          setSaving(false)
        });
    } else {
      createPost()
    }
  }

  const createPost = (featureMediaId) => {
    wpClient.posts().create({
      title: title,
      content: content,
      status: 'publish',
      comment_status: 'open',
      author: user.id,
      featured_media: featureMediaId,
      password: pwd,
      categories: [
        category.id
      ]
    }).then(function (response) {
      if (featureMediaId) {
        linkMediaToPost(response.id, featureMediaId)
      } else {
        setSaving(false)
        window.location.reload()
      }
    }).catch(error => {
      console.error(error)
      setSaving(false)
    });
  }

  const linkMediaToPost = (postId, featureMediaId) => {
    wpClient.media().id(featureMediaId)
      .update({
        post: postId
      })
      .then(function () {
        setSaving(false)
        window.location.reload()
      })
      .catch(error => {
        console.error(error)
        setSaving(false)
      });
  }

  const isPostValid = () => {
    return content.trim().length >= contentMinLength && title.trim().length > titleMinLength
  }

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      <div className="card-body p-0">
        <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>
        Crea post in '{category.name.replace(/&amp;/g, '&')}'
      </div>
      <form onSubmit={onSubmitPost}>
        <div style={{marginBottom: "15px"}} className="card-body p-0 mt-3 position-relative bor-0 border-light-md theme-dark-bg rounded-xxl">
          <figure className="avatar position-absolute ms-2 mt-1 top-5">
            <AvatarImg src={GetUserAvatar(user)} classes="shadow-sm rounded-circle w30" />
          </figure>
          <input
            required
            value={title}
            minLength={titleMinLength}
            onChange={(ev) => setTitle(ev.target.value)}
            style={{border: "none"}}
            className="w-100 p-2 ps-5 font-xssss fw-500 rounded-xxl"
            type="text" placeholder={`Titolo (min. ${titleMinLength} caratteri)`}/>
          <textarea
            style={{border: "none", height: "50px"}}
            required
            minLength={contentMinLength}
            name="message"
            className="w-100 p-2 ps-5 font-xssss fw-500"
            cols="30" rows="10" placeholder={`Contenuto del post (min. ${contentMinLength} caratteri)`}
            value={content}
            onChange={(ev) => setContent(ev.target.value)}/>
        </div>
        <div className="card-body d-flex p-0 mt-0">
          <div className="sub-post-elements form-group mb-0 w-100">
            <div className="featured-image-input">
              <input type="file" name="file" id="featured-image" className="input-file" accept="image/jpeg,image/png"
                     onClick={() => setFeaturedImage(null)}
                     onChange={onFeatureImageSelected}/>
              <label htmlFor="featured-image" className="rounded-3 bg-white btn-tertiary">
                <i className="font-md text-success feather-image me-2"></i>
                <span>
                {
                  featuredImage && `${featuredImage.name} (clicca per selezionare un'altra immagine)`
                }
                  {
                    !featuredImage && "Carica un'immagine"
                  }
              </span>
              </label>
            </div>
            <div className="submit-button">
              <button
                type="submit"
                title={!isPostValid() || saving ? `Digita almeno ${contentMinLength} caratteri` : "Salva post"}
                className={"post-btn align-items-right text-center text-grey-700 font-xsss rounded-3 d-inline-block " + (saving ? "disabled" : "")}>
                {saving ? "Attendi.." : "Invia"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

CreatePost.propTypes = {
  user: PropTypes.object,
  category: PropTypes.object.isRequired,
}

export default CreatePost;