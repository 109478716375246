import React, {Fragment, useCallback, useContext, useEffect, useState} from "react";
import axios from "axios";
import AuthContext from "../../components/AuthContext";
import {Link, useNavigate, useSearchParams} from "react-router-dom";

const Login = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(null)
  const authCtx = useContext(AuthContext);

  const navigateToR = useCallback(() => {
    const r = searchParams.get("r")
    navigate(r ? r : "/")
  }, [navigate])

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      navigateToR()
    }
  }, [authCtx, navigateToR])

  const handleLogin = (auth) => {
    authCtx.onLogin(auth)
  }

  const onSubmit = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    axios.post(process.env.REACT_APP_WP_JSON + '/jwt-auth/v1/token', {
      username: email,
      password: password
    })
      .then(function (response) {
        setError(null)
        handleLogin(response.data)
      })
      .catch(function (error) {
        console.error(error)
        setError(error)
      });
  }

  return (
    <Fragment>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100">
            <button className="nav-menu me-0 ms-auto"></button>
            <a href={process.env.REACT_APP_HOME_URL + "/iscrizione"}
               rel="noreferrer"
               target="_blank"
               className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Registrati</a>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
               style={{backgroundImage: `url("/assets/images/login_background.jpeg")`}}></div>
          <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-3">Accedi al<br/> portale 22e22</h2>
                <form onSubmit={onSubmit}>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                    <input type="text" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                           placeholder="Username o indirizzo email" value={email}
                           onChange={(ev) => setEmail(ev.target.value)}/>
                  </div>
                  <div className="form-group icon-input mb-1">
                    <input type="Password" className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                           placeholder="Password" value={password} onChange={(ev) => setPassword(ev.target.value)}/>
                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                  </div>
                  <div className="form-check text-left mb-3">
                    <Link to="/forgot" className="fw-600 font-xsss text-grey-700 mt-1 float-right">
                      Dimenticato la password?
                    </Link>
                  </div>
                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      <button type="submit"
                              className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Accedi
                      </button>
                      {
                        error != null &&
                        <span style={{color: "red"}}>Credenziali non valide o utente non abilitato</span>
                      }
                    </div>
                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                      Non hai un account?
                      <a href={process.env.REACT_APP_HOME_URL + "/iscrizione"}
                         rel="noreferrer"
                         target="_blank"
                         className="fw-700 ms-1">Registrati subito!</a>
                    </h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;