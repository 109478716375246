import React, {useContext} from "react";
import {Link, useNavigate} from 'react-router-dom';
import AuthContext from "../components/AuthContext";

const Settings = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate()
  return (
    <div className="main-content theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xss p-0 mb-4">
              <div className="card-body p-lg-5 p-4 w-100 border-0">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">Impostazioni</h4>
                    <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">Generali</div>
                    <ul className="list-inline mb-4">
                      <li className="list-inline-item d-block border-bottom me-0">
                        <Link to="/impostazioni/account" className="pt-2 pb-2 d-flex align-items-center">
                          <i className="btn-round-md bg-primary-gradiant text-white feather-home font-md me-3"></i>
                          <h4 className="fw-600 font-xsss mb-0 mt-0">Modifica profilo</h4>
                          <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">Account</div>
                    <ul className="list-inline mb-4">
                      <li className="list-inline-item d-block border-bottom me-0">
                        <Link to="/notifiche" className="pt-2 pb-2 d-flex align-items-center"><i
                          className="btn-round-md bg-gold-gradiant text-white feather-bell font-md me-3"></i> <h4
                          className="fw-600 font-xsss mb-0 mt-0">Notifiche</h4><i
                          className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                      <li className="list-inline-item d-block  me-0"><Link to="/impostazioni/password"
                                                                           className="pt-2 pb-2 d-flex align-items-center"><i
                        className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i> <h4
                        className="fw-600 font-xsss mb-0 mt-0">Cambia password</h4><i
                        className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                    </ul>
                    <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">Altro</div>
                    <ul className="list-inline">
                      <li className="list-inline-item d-block border-bottom me-0">
                        <Link to="/policy" className="pt-2 pb-2 d-flex align-items-center"><i
                          className="btn-round-md bg-primary-gradiant text-white feather-help-circle font-md me-3"></i>
                          <h4 className="fw-600 font-xsss mb-0 mt-0">Aiuto</h4><i
                            className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                      <li className="list-inline-item d-block me-0">
                        <a
                          href="#" onClick={() => {
                          authCtx.onLogout()
                          navigate("/login")
                        }}
                          className="pt-2 pb-2 d-flex align-items-center">
                          <i className="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i>
                          <h4 className="fw-600 font-xsss mb-0 mt-0">Esci</h4>
                          <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;