import React from "react";

import './SectionProfileCard.scss'
import PropTypes from "prop-types";

const SectionProfileCard = (props) => {
  const category = props.category
  return (
    <div className="section-profile-card card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 mt-3 pb-3">
      <div className={`card-body position-relative h150 bg-${category.acf.color}-gradiant`}
           style={{backgroundColor: `url("https://via.placeholder.com/1200x250.png")`}}/>
      <div className="card-body d-block pt-4 text-center">
        <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
          <div className="section-image p-1 bg-white rounded-xl w-100">
            <i className={category.acf["category_feather_icon"] + ` p-1 bg-white rounded-xl w-100`} />
          </div>
        </figure>
        <h4 className="font-xs ls-1 fw-700 text-grey-900">
          {category.name.replace(/&amp;/g, '&')}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">@{category.slug}</span>
        </h4>
      </div>
    </div>
  );
}

SectionProfileCard.propTypes = {
  category: PropTypes.object.isRequired,
}

export default SectionProfileCard