import React, {useEffect, useState} from "react";

import './AccountPublic.scss'
import {useNavigate, useParams} from "react-router-dom";
import AvatarImg from "../../components/AvatarImg";
import {GetUserAvatarFull} from "../../utils/User";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import Load from "../../components/Load";
import {ColorExtractor} from "react-color-extractor";

const AccountPublic = () => {
  const {id} = useParams();
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const navigate = useNavigate();
  const wpClient = useRecoilValue(wpClientSelector)
  const [bgColor, setBgColor] = useState("#7f7f7f")

  useEffect(() => {
    wpClient.users().id(id).embed()
      .then(function (response) {
        setUser(response)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setError("Non è stato possibile caricare i dati dell'utente")
        console.error(error)
      });
  }, [id]);

  if (error.length > 0) {
    return (
      <div className="main-content theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3" style={{backgroundColor: `${bgColor}`}}>
                  <a onClick={() => navigate(-1)} className="d-inline-block mt-2 cursor-pointer"><i
                    className="ti-arrow-left font-sm text-white"></i></a>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Visualizza profilo</h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  {error}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="main-content theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3" style={{backgroundColor: `${bgColor}`}}>
                  <a onClick={() => navigate(-1)} className="d-inline-block mt-2 cursor-pointer"><i
                    className="ti-arrow-left font-sm text-white"></i></a>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Visualizza profilo</h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <Load/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const getColors = colors => {
    setBgColor(colors[0]);
  }

  return (
    <div className="main-content theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              <div className="card-body p-4 w-100 border-0 d-flex rounded-3" style={{backgroundColor: `${bgColor}`}}>
                <a onClick={() => navigate(-1)} className="d-inline-block mt-2 cursor-pointer"><i
                  className="ti-arrow-left font-sm text-white"></i></a>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Visualizza profilo</h4>
              </div>
              <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                <div className="row justify-content-center">
                  <div className="col-lg-4 text-center">
                    <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                      <ColorExtractor src={GetUserAvatarFull(user)} rgb={false} getColors={getColors} onError={() => {}} />
                      <AvatarImg src={GetUserAvatarFull(user)} classes="shadow-sm rounded-3 w-100"/>
                    </figure>
                    <h2 className="fw-700 font-sm text-grey-900 mt-3">{user.name}</h2>
                    {
                      user.hide_email !== "1" &&
                      <h4 className="text-grey-500 fw-500 font-xsss">
                        <a href={`mailto:${user.user_email}`}>{user.user_email}</a>
                      </h4>
                    }
                    <h4 className="text-grey-500 fw-500 font-xsss mb-3">@{user.slug}</h4>
                  </div>
                  <div className="col-lg-12 text-center mb-5">
                    {
                      user.social_facebook &&
                      <i className="social-icon feather-facebook" title="Facebook" onClick={() => window.open(user.social_facebook)} />
                    }
                    {
                      user.social_instagram &&
                      <i className="social-icon feather-instagram" title="Instagram" onClick={() => window.open(user.social_instagram)} />
                    }
                    {
                      user.social_twitter &&
                      <i className="social-icon feather-twitter" title="Twitter" onClick={() => window.open(user.social_twitter)} />
                    }
                    {
                      user.social_linkedin &&
                      <i className="social-icon feather-linkedin" title="Linkedin" onClick={() => window.open(user.social_linkedin)} />
                    }
                    {
                      user.social_website &&
                      <i className="social-icon feather-chrome" title="Website" onClick={() => window.open(user.social_website)} />
                    }
                  </div>
                  {
                    user.description &&
                    <div className="col-lg-12 mb-3 text-center">
                      {user.description}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountPublic