import React, {Fragment, useCallback, useContext, useEffect, useState} from "react";

import './Forgot.scss'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../components/AuthContext";

const Forgot = () => {
  const [loading, setLoading] = useState(false)
  const [userLogin, setUserLogin] = useState("")
  const navigate = useNavigate()
  const authCtx = useContext(AuthContext);

  const navigateToHomepage = useCallback(() => {
    navigate('/')
  }, [navigate])

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      navigateToHomepage()
    }
  }, [authCtx, navigateToHomepage])

  const onSubmitForgot = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    setLoading(true)
    axios
      .get(process.env.REACT_APP_HOME_URL + 'forgot', {
        params: {
          user_login: userLogin,
        }
      })
      .then(function () {
        navigate("/")
      })
      .catch(function (error) {
        navigate("/")
      });
  }

  return (
    <Fragment>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100">
            <button className="nav-menu me-0 ms-auto"></button>
            <a href={process.env.REACT_APP_HOME_URL + "/iscrizione"}
               rel="noreferrer"
               target="_blank"
               className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Registrati</a>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
               style={{backgroundImage: `url("/assets/images/login_background.jpeg")`}}></div>
          <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-3">Recupera la password</h2>
                <span>Sei il tuo utente esiste, un link per resettare la password ti verrà inviato via mail</span>
                <br/><br/>
                <form onSubmit={onSubmitForgot}>
                  <div className="form-group icon-input mb-1">
                    <input
                      type="text" required className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                      placeholder="Username o indirizzo email"
                      value={userLogin} onChange={(ev) => setUserLogin(ev.target.value)}/>
                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                  </div>
                  <div className="form-check text-left">
                  </div>
                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      <button disabled={loading} type="submit"
                              className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">
                        {loading ? "Attendi" : "Recupera"}
                      </button>
                    </div>
                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                      Non hai un account?
                      <a href={process.env.REACT_APP_HOME_URL + "/iscrizione"}
                         rel="noreferrer"
                         target="_blank"
                         className="fw-700 ms-1">Registrati subito!</a>
                    </h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Forgot;